import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
const Header = () => {
	let userData = JSON.parse(sessionStorage.getItem("userData"));
	const navigate = useNavigate();
	console.log(userData.result[0].id);
	
	  const handleLogout = (userId) => {
		Swal.fire({
		  title: "Logout",
		  text: "Are you sure you want to logout?",
		  icon: "warning",
		  showCancelButton: true,
		  confirmButtonColor: "#3085d6",
		  cancelButtonColor: "#d33",
		  confirmButtonText: "Yes, logout!",
		}).then((result) => {
		  if (result.isConfirmed) {
			axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout/${userId}`)
			  .then(res => {
				if (res.data.success === 0) {
				  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				  sessionStorage.removeItem('userData');
				  navigate("/login");
		
				  Swal.fire({
					icon: "success",
					title: "Logout",
					text: "Logout successful",
					timer: 1500,
				  });
				} else {
				  // If logout fails, show an error message
				  Swal.fire({
					icon: "error",
					title: "Logout",
					text: "Logout failed. Please try again.",
				  });
				}
			  })
			  .catch(err => console.log(err));
		  }
		});
	  }
  return (
    <>
     <div className="wrapper">
     {/* <!--start header --> */}
		<header>
			<div className="topbar d-flex align-items-center">
				<nav className="navbar navbar-expand gap-3">
					<div className="mobile-toggle-menu"><i className='bx bx-menu'></i>
					</div>

					  <div className="top-menu ms-auto">
						<ul className="navbar-nav align-items-center gap-1">

							<li className="nav-item dark-mode d-none d-sm-flex">
								<a className="nav-link dark-mode-icon" href="javascript:;"><i className='bx bx-moon'></i>
								</a>
							</li>
							
							{/* <li className="nav-item dropdown dropdown-app">
								<a className="nav-link dropdown-toggle dropdown-toggle-nocaret" data-bs-toggle="dropdown" href="javascript:;"><i className='bx bx-grid-alt'></i></a>
								<div className="dropdown-menu dropdown-menu-end p-0">
									<div className="app-container p-2 my-2">
									  <div className="row gx-0 gy-2 row-cols-3 justify-content-center p-2">
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/slack.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Slack</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/behance.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Behance</p>
											  </div>
											  </div>
										  </a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												<img src="assets/images/app/google-drive.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Dribble</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/outlook.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Outlook</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/github.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">GitHub</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/stack-overflow.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Stack</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/figma.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Stack</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/twitter.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Twitter</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/google-calendar.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Calendar</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/spotify.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Spotify</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/google-photos.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Photos</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/pinterest.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Photos</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/linkedin.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">linkedin</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/dribble.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Dribble</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/youtube.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">YouTube</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/google.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">News</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/envato.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Envato</p>
											  </div>
											  </div>
											</a>
										 </div>
										 <div className="col">
										  <a href="javascript:;">
											<div className="app-box text-center">
											  <div className="app-icon">
												  <img src="assets/images/app/safari.png" width="30" alt="" />
											  </div>
											  <div className="app-name">
												  <p className="mb-0 mt-1">Safari</p>
											  </div>
											  </div>
											</a>
										 </div>
				
									  </div>
				
									</div>
								</div>
							</li> */}

							{/* <li className="nav-item dropdown dropdown-large">
								<a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" data-bs-toggle="dropdown"><span className="alert-count">7</span>
									<i className='bx bx-bell'></i>
								</a>
								<div className="dropdown-menu dropdown-menu-end">
									<a href="javascript:;">
										<div className="msg-header">
											<p className="msg-header-title">Notifications</p>
											<p className="msg-header-badge">8 New</p>
										</div>
									</a>
									<div className="header-notifications-list">
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="user-online">
													<img src="assets/images/avatars/avatar-1.png" className="msg-avatar" alt="user avatar" />
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">Daisy Anderson<span className="msg-time float-end">5 sec
												ago</span></h6>
													<p className="msg-info">The standard chunk of lorem</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="notify bg-light-danger text-danger">dc
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">New Orders <span className="msg-time float-end">2 min
												ago</span></h6>
													<p className="msg-info">You have recived new orders</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="user-online">
													<img src="assets/images/avatars/avatar-2.png" className="msg-avatar" alt="user avatar" />
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">Althea Cabardo <span className="msg-time float-end">14
												sec ago</span></h6>
													<p className="msg-info">Many desktop publishing packages</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="notify bg-light-success text-success">
													<img src="assets/images/app/outlook.png" width="25" alt="user avatar" />
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">Account Created<span className="msg-time float-end">28 min
												ago</span></h6>
													<p className="msg-info">Successfully created new email</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="notify bg-light-info text-info">Ss
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">New Product Approved <span
												class="msg-time float-end">2 hrs ago</span></h6>
													<p className="msg-info">Your new product has approved</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="user-online">
													<img src="assets/images/avatars/avatar-4.png" className="msg-avatar" alt="user avatar" />
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">Katherine Pechon <span className="msg-time float-end">15
												min ago</span></h6>
													<p className="msg-info">Making this the first true generator</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="notify bg-light-success text-success"><i className='bx bx-check-square'></i>
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">Your item is shipped <span className="msg-time float-end">5 hrs
												ago</span></h6>
													<p className="msg-info">Successfully shipped your item</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="notify bg-light-primary">
													<img src="assets/images/app/github.png" width="25" alt="user avatar" />
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">New 24 authors<span className="msg-time float-end">1 day
												ago</span></h6>
													<p className="msg-info">24 new authors joined last week</p>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center">
												<div className="user-online">
													<img src="assets/images/avatars/avatar-8.png" className="msg-avatar" alt="user avatar" />
												</div>
												<div className="flex-grow-1">
													<h6 className="msg-name">Peter Costanzo <span className="msg-time float-end">6 hrs
												ago</span></h6>
													<p className="msg-info">It was popularised in the 1960s</p>
												</div>
											</div>
										</a>
									</div>
									<a href="javascript:;">
										<div className="text-center msg-footer">
											<button className="btn btn-primary w-100">View All Notifications</button>
										</div>
									</a>
								</div>
							</li> */}
							{/* <li className="nav-item dropdown dropdown-large">
								<a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span className="alert-count">8</span>
									<i className='bx bx-shopping-bag'></i>
								</a>
								<div className="dropdown-menu dropdown-menu-end">
									<a href="javascript:;">
										<div className="msg-header">
											<p className="msg-header-title">My Cart</p>
											<p className="msg-header-badge">10 Items</p>
										</div>
									</a>
									<div className="header-message-list">
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/11.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/02.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/03.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/04.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/05.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/06.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/07.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/08.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
										<a className="dropdown-item" href="javascript:;">
											<div className="d-flex align-items-center gap-3">
												<div className="position-relative">
													<div className="cart-product rounded-circle bg-light">
														<img src="assets/images/products/09.png" className="" alt="product image" />
													</div>
												</div>
												<div className="flex-grow-1">
													<h6 className="cart-product-title mb-0">Men White T-Shirt</h6>
													<p className="cart-product-price mb-0">1 X $29.00</p>
												</div>
												<div className="">
													<p className="cart-price mb-0">$250</p>
												</div>
												<div className="cart-product-cancel"><i className="bx bx-x"></i>
												</div>
											</div>
										</a>
									</div>
									
								</div>
							</li> */}
						</ul>
					</div>
					<div className="user-box dropdown px-3">
						{/* <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> */}
							<img src="assets/images/avatars/avatar-2.png" className="user-img" alt="user avatar" />
							<div className="user-info">
								<p className="user-name mb-0">{userData.result[0].name || " "}</p>
								<p className="designattion mb-0">{userData.role || " "}</p>
							</div>
						{/* </a> */}
						
					</div>
				</nav>
			</div>
		</header>
		{/* <!--end header --> */}
        {/* <!--start overlay--> */}
		 <div className="overlay toggle-icon"></div>
		{/* <!--end overlay--> */}
		{/* <!--Start Back To Top Button--> */}
		  <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
		{/* <!--End Back To Top Button--> */}
	
     </div> 
      
     {/* <!--start switcher--> */}
	<div className="switcher-wrapper">
		<div className="switcher-btn"> <i className='bx bx-cog bx-spin'></i>
		</div>
		<div className="switcher-body">
			<div className="d-flex align-items-center">
				<h5 className="mb-0 text-uppercase">Theme Customizer</h5>
				<button type="button" className="btn-close ms-auto close-switcher" aria-label="Close"></button>
			</div>
			<hr/>
			<h6 className="mb-0">Theme Styles</h6>
			<hr/>
			<div className="d-flex align-items-center justify-content-between">
				<div className="form-check">
					<input className="form-check-input" type="radio" name="flexRadioDefault" id="lightmode" checked/>
					<label className="form-check-label" for="lightmode">Light</label>
				</div>
				<div className="form-check">
					<input className="form-check-input" type="radio" name="flexRadioDefault" id="darkmode"/>
					<label className="form-check-label" for="darkmode">Dark</label>
				</div>
				<div className="form-check">
					<input className="form-check-input" type="radio" name="flexRadioDefault" id="semidark"/>
					<label className="form-check-label" for="semidark">Semi Dark</label>
				</div>
			</div>
			<hr/>
			<div className="form-check">
				<input className="form-check-input" type="radio" id="minimaltheme" name="flexRadioDefault"/>
				<label className="form-check-label" for="minimaltheme">Minimal Theme</label>
			</div>
			<hr/>
			<h6 className="mb-0">Header Colors</h6>
			<hr/>
			<div className="header-colors-indigators">
				<div className="row row-cols-auto g-3">
					<div className="col">
						<div className="indigator headercolor1" id="headercolor1"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor2" id="headercolor2"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor3" id="headercolor3"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor4" id="headercolor4"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor5" id="headercolor5"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor6" id="headercolor6"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor7" id="headercolor7"></div>
					</div>
					<div className="col">
						<div className="indigator headercolor8" id="headercolor8"></div>
					</div>
				</div>
			</div>
			<hr/>
			<h6 className="mb-0">Sidebar Colors</h6>
			<hr/>
			<div className="header-colors-indigators">
				<div className="row row-cols-auto g-3">
					<div className="col">
						<div className="indigator sidebarcolor1" id="sidebarcolor1"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor2" id="sidebarcolor2"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor3" id="sidebarcolor3"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor4" id="sidebarcolor4"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor5" id="sidebarcolor5"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor6" id="sidebarcolor6"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor7" id="sidebarcolor7"></div>
					</div>
					<div className="col">
						<div className="indigator sidebarcolor8" id="sidebarcolor8"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	{/* <!--end switcher--> */}
    </>
  )
}

export default Header
