import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Register = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState({
    name: "",
    email: "",
    contact: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValue((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const contactRegex = /^\d{10}$/;

      if (!value.name || !value.email || !value.contact || !value.password) {
        // Check if any value is missing
        Swal.fire({
          title: "Please enter all data",
          icon: "warning",
        });
      } else if (!emailRegex.test(value.email)) {
        // Email format validation
        Swal.fire({
          title: "Invalid Email Format",
          text: "Please enter a valid email address",
          icon: "warning",
        });
      } else if (!contactRegex.test(value.contact)) {
        // Contact format validation
        Swal.fire({
          title: "Invalid Contact Format",
          text: "Contact number must be exactly 10 digits",
          icon: "warning",
        });
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/registration`,
          value
        );

        console.log(res); // Check the response in the console

        if (res.status === 200) {
          // Successful request
          Swal.fire({
            icon: "success",
            title: "Registration Successfully",
            timer: 1500,
          }).then(() => {
            navigate("/login");
          });
        } else {
          // Unsuccessful request
          Swal.fire({
            title: "Registration failed",
            icon: "error",
          });
        }
      }
    } catch (err) {
      console.error(err);

      Swal.fire({
        icon: "error",
        title: err.message || "Something went wrong",
        showConfirmButton: true,
      });
      if (err.response && err.response.status === 400) {
        Swal.fire({
          icon: "warning",
          title: "This Data Already Exists",
          confirmButtonText: "OK",
          confirmButtonColor: "#06bdff",
        });
      }
    }
  };
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="wrapper d-flex align-items-center justify-content-center my-5">
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
            <div className="col mx-auto">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="p-4">
                    <div className="mb-3 text-center">
                      <img
                        src="assets/images/logo-icon.png"
                        width="100"
                        alt=""
                      />
                    </div>
                    <div className="text-center mb-4">
                      <h5 className="">Syndron Admin</h5>
                      <p className="mb-0">
                        Please fill the below details to create your account
                      </p>
                    </div>
                    <div className="form-body">
                      <form className="row g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label for="inputUsername" className="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="inputUsername"
                            placeholder="Please Enter User Name"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-12">
                          <label for="inputEmailAddress" className="form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="inputEmailAddress"
                            placeholder="Please Enter Address"
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="col-12">
                          <label
                            htmlFor="inputChoosePassword"
                            className="form-label"
                          >
                            Password
                          </label>
                          <div className="password-input-container-1">
                            <input
                              className="form-control border-end-0"
                              id="inputChoosePassword"
                              placeholder="Enter Password"
                              name="password"
                              onChange={handleInputChange}
                              type={passwordVisible ? "text" : "password"}
                            />
                            <div
                              className="eye-icon-wrapper"
                              onClick={togglePasswordVisibility}
                            >
                              {passwordVisible ? (
                                <i className="bi bi-eye-slash"></i>
                              ) : (
                                <i className="bi bi-eye"></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <label
                            for="inputSelectCountry"
                            className="form-label"
                          >
                            Contact
                          </label>
                          <input
                            type="contact"
                            name="contact"
                            className="form-control"
                            id="inputContact"
                            placeholder="Please Enter Contact Number"
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button type="submit" className="btn btn-primary">
                              Sign up
                            </button>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="text-center ">
                            <p className="mb-0">
                              Already have an account?{" "}
                              <NavLink to="/login">Sign in here</NavLink>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
