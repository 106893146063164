import React, { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Login = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [accessToken, setAccessToken] = useState(null);
  const [requestToken, setRequestToken] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const requestToken = urlParams.get("request_token");
    setRequestToken(requestToken);
  }, []);

  const getAccessToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/login`, {
        request_token: requestToken,
      });
      if (response.data.success === 0) {
        setAccessToken(response.data.data.access_token);
        console.log(response.data.data);
      } else {
        console.error("Error fetching access token:", response.data.error);
        if (response.status === 403) {
          localStorage.removeItem("accessToken");
        }
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  useEffect(() => {
    if (requestToken) {
      getAccessToken();
    }
  }, [requestToken]);
  axios.defaults.withCredentials = true;
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const ValidateEmail = () => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return values.email.match(validRegex);
  };
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!values.email || !values.password) {
        Swal.fire({
          title: "Please enter all data",
          icon: "warning",
        });
      } else if (!ValidateEmail()) {
        Swal.fire({
          type: "warning",
          icon: "warning",
          title: "Invalid Email Address",
        });
      } else {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/login`, values);
        if (res.status === 200) {
          const userData = JSON.parse(sessionStorage.getItem("userData"));
          if (userData) {
            // Clear existing user data
            sessionStorage.removeItem("userData");
          }
          if (res.data.data.result[0].status === 1) {
            sessionStorage.setItem(
              "userData",
              JSON.stringify({ ...res.data.data, role: res.data.role })
            );
            switch (res.data.role) {
              case "admin":
                Swal.fire({
                  icon: "success",
                  title: "Admin Login successfully",
                  timer: 1500,
                }).then(() => {
                  navigate("/");
                });
                break;
              case "user":
                Swal.fire({
                  icon: "success",
                  title: "User Login successfully",
                  timer: 1500,
                }).then(() => {
                  navigate("/");
                });
                break;
              default:
                break;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Your account is Inactivated",
            });
          }
        } else {
          Swal.fire({
            title: "Login insertion failed",
            icon: "error",
          });
        }
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 400) {
        if (err.response.data.message === "Invalid email or password") {
          Swal.fire({
            icon: "error",
            title: "Invalid Email or Password",
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Invalid Email or Password",
          });
        }
      }
    }
  };  

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      {/* <!--wrapper--> */}
      <div className="wrapper">
        <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
              <div className="col mx-auto">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="p-4">
                      <div className="mb-3 text-center">
                        <img
                          src="assets/images/logo-icon.png"
                          width="100"
                          alt=""
                        />
                      </div>
                      <div className="text-center mb-4">
                        <h5 className=""></h5>
                        <p className="mb-0">Please log in to your account</p>
                      </div>
                      <div className="form-body">
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <div className="col-12">
                            <label
                              for="inputEmailAddress"
                              className="form-label"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmailAddress"
                              name="email"
                              onChange={handleInputChange}
                              placeholder="Please Enter Email"
                            />
                          </div>
                          <div className="col-12">
                            <label
                              for="inputChoosePassword"
                              className="form-label"
                            >
                              Password
                            </label>
                            <div
                              className="input-group"
                              id="show_hide_password"
                            >
                              <div className="password-input-container">
                                <input
                                  className="form-control border-end-0"
                                  id="inputChoosePassword"
                                  placeholder="Enter Password"
                                  name="password"
                                  onChange={handleInputChange}
                                  type={passwordVisible ? "text" : "password"}
                                />
                                <div className="eye-icon-wrapper-1" onClick={togglePasswordVisibility}>
                                  {passwordVisible ? (
                                    <i className="bi bi-eye-slash"></i>
                                  ) : (
                                    <i className="bi bi-eye"></i>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 ">
                            < div id="pwd">
                            <NavLink to="/forgotpassword">
                              Forgot Password ?
                            </NavLink>
                          </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button type="submit" className="btn btn-primary">
                                Sign in
                              </button>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="text-center ">
                              <p className="mb-0">
                                Don't have an account yet?{" "}
                                <NavLink to="/register">Sign up here</NavLink>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end wrapper-->  */}
    </>
  );
};

export default Login;
