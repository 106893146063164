import React, { useState, useEffect, useRef} from "react";
import "../style.css";
import { useNavigate } from "react-router-dom";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "../style.css"; 
import { TimePicker, Space } from 'antd';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField'; 
import Chart from 'chart.js/auto'; 
import zoomPlugin from 'chartjs-plugin-zoom';
import Swal from 'sweetalert2';
import axios from "axios";
import { Line } from 'react-chartjs-2'; 
import 'chartjs-adapter-moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

Chart.register(zoomPlugin);
const BackTester = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const checkUserStatus = async () => {
		  try {
			const userData = JSON.parse(sessionStorage.getItem("userData"));
			if (!userData) {
			  // If userData is not available, redirect to login
			  navigate("/login");
			  return;
			}
	
			const userId = userData.result[0].id;
	
			const res = await axios.get(
			  `${process.env.REACT_APP_BACKEND_BASE_URL}/getadmin/${userId}`
			);
	
			if (res.status === 200 && res.data.data.length > 0) {
			  const userDataFromServer = res.data.data[0];
			  if (userDataFromServer.status === 0) {
				// If user status is inactive, clear sessionStorage and redirect to login
				sessionStorage.clear();
				Swal.fire({
				  icon: "error",
				  title: "Your account is deactivated",
				});
				navigate("/login");
			  }
			}
		  } catch (err) {
			console.log(err);
			Swal.fire({
			  icon: "error",
			  title: "Something went wrong",
			});
		  }
		};
	
		// Initial check when component mounts
		checkUserStatus();
	
		// Set up interval to check user status every 2 minutes
		const intervalId = setInterval(checkUserStatus, 2 * 60 * 1000);
	
		// Clear interval on component unmount
		return () => clearInterval(intervalId);
	  }, []);
	const revenueData = [
		{ "label": "10:30:02", "OI": 10, "OI Change": 20 ,"Volume" :30},
		{ "label": "10:31:02", "OI": 50, "OI Change": 70 ,"Volume" :40},
		{ "label": "10:32:02", "OI": 30, "OI Change": 80 ,"Volume" :50},
		{ "label": "10:33:02", "OI": 10, "OI Change": 29 ,"Volume" :60 },
		{ "label": "10:34:02", "OI": 79, "OI Change": 65 ,"Volume" :70},
		{ "label": "10:35:02", "OI": 86, "OI Change": 22 ,"Volume" :80},
		{ "label": "10:36:02", "OI": 65, "OI Change": 56 ,"Volume" :90},
		{ "label": "10:37:02", "OI": 96, "OI Change": 45 ,"Volume" :10},
		{ "label": "10:38:02", "OI": 30, "OI Change": 70 ,"Volume" :20},
		{ "label": "10:39:02", "OI": 40, "OI Change": 30 ,"Volume" :30},
		{ "label": "10:40:02", "OI": 10, "OI Change": 90 ,"Volume" :40},
		{ "label": "10:45:02", "OI": 30, "OI Change": 20 ,"Volume" :60}
	  ];

	  const [activeTab, setActiveTab] = useState('oi-change');

	  const handleTabChange = (tabId) => {
		  setActiveTab(tabId);
	  };
	  const [nextTsl2, setNextTsl2] = useState(0); 
	  const [expiryDate, setExpiryDate] = useState([]);
	  const [date, setDate] = useState([]);
	  const [selectedExpiryDate, setSelectedExpiryDate] = useState('');
	  const [selectedDate, setSelectedDate] = useState('');
	  const [niftyFilterDb, setNiftyFilterDb] = useState([]);
	  const [upperCe, setUpperCe] = useState(null);
	  const [lowerCe, setLowerCe] = useState(null);
	  const [spotPrice, setSpotPrice] = useState(null);
	  const [selectedTime, setSelectedTime] = useState(null);
	  const [isIntervalRunning, setIsIntervalRunning] = useState(false);
	  const intervalRef = useRef(null);

	const fetchExpiryDate = async () => {
		try {
		const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/getNiftyExpiryDay`, {
			headers: {
			'Content-Type': 'application/json'
			}
		});
		setExpiryDate(response.data.data);
		console.log("expiry", response.data.data);
		} catch (error) {
		console.error("Error fetching Nifty Db data:", error);
		}
  };
  
	  const fetchDate = async (selectedExpiryDate) => {
		  try {
			  const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/getNiftyDate?expiry_date=${selectedExpiryDate}`, {
				  headers: {
					  'Content-Type': 'application/json'
				  }
			  });
  
			  console.log("expiry date", selectedExpiryDate);
			  const formattedDates = response.data.data.map(item => item.date.split('T')[0]);
			  setDate(formattedDates);
			  console.log("dates", formattedDates);
		  } catch (error) {
			  console.error("Error fetching Nifty Db data:", error);
		  }
	  };

	  const disabledTime = () => {
		return {
		  disabledHours: () => {
			const hours = [];
			for (let i = 0; i <= 8; i++) {
			  hours.push(i);
			}
			for (let i = 16; i <= 23; i++) {
			  hours.push(i);
			}
			return hours;
		  },
		  disabledMinutes: (selectedHour) => {
			if (selectedHour === 15) {
			  // Disable minutes after 30
			  return Array.from({ length: 35 }, (_, i) => i + 36);
			}
			return [];
		  },
		  disabledSeconds: () => {
			return [];
		  },
		};
	  };
	  const [selectedTimeForFetch, setSelectedTimeForFetch] = useState(null);
	  const isTimeDisabled = (time) => {
		const hour = time.hour();
		const minute = time.minute();
		const disabledHours = disabledTime().disabledHours();
		const disabledMinutes = disabledTime().disabledMinutes(hour);
	  
		if (disabledHours.includes(hour)) {
		  return true;
		}
	  
		if (hour === 15 && disabledMinutes.includes(minute)) {
		  return true;
		}
	  
		return false;
	  };
	  
	  const handleIntervalClick = (value) => {
		clearInterval(intervalRef.current);
		intervalRef.current = setInterval(() => {
		  setSelectedTime(prevTime => {
			const currentTime = dayjs(prevTime, 'HH:mm');
			const newTime = currentTime.add(value, 'minute');
			console.log("New time:", newTime.format('HH:mm'));
			setSelectedTimeForFetch(newTime.format('HH:mm')); // Update selected time for fetch
	        if (isTimeDisabled(newTime)) {
				console.log("Selected time is disabled, stopping interval.");
				stopInterval();
				return prevTime; // Return previous time, do not update
			  }
		
			const fetchData = async () => {
			  try {
				const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/getNiftyBackTester`, {
				  headers: {
					'Content-Type': 'application/json'
				  },
				  params: {
					expiry_date: selectedExpiryDate,
					date: selectedDate,
					time: newTime.format('HH:mm') // Format the time correctly here
				  }
				});
				console.log(response);
				if (response && response.data && response.data.data && response.data.data.length >= 20) {
				  console.log("interval", response.data);
				  setNiftyFilterDb(response.data.data);
	  
				  const spotPrice = response.data.data[0]?.spot_price;
				  if (spotPrice !== undefined) {
					setSpotPrice(spotPrice);
					console.log("spot interval",spotPrice);
					const remainder = spotPrice % 50;
					const lowerRoundedValue = spotPrice - remainder;
					const upperRoundedValue = lowerRoundedValue + 50;
					const adjustedLower = Math.floor(lowerRoundedValue / 50) * 50;
					const adjustedUpper = Math.ceil(upperRoundedValue / 50) * 50;
	  
					setUpperCe(adjustedUpper);
					setLowerCe(adjustedLower);
				  } else {
					console.error("spot_price is undefined.");
				  }
				} else {
				  console.error("Empty or insufficient response data received.");
				}
			  } catch (error) {
				console.error("Error fetching Nifty Db data:", error);
			  }
			};
	  
			fetchData();
			return newTime.format('HH:mm'); // Store the new time object directly
		  });
		}, 10000); // Update every 10 seconds for debugging
		setIsIntervalRunning(true);
	  };
	
	  const startInterval = () => {
		fetchData(selectedTimeForFetch); // Fetch data using the latest time
	  };
	  
	  const stopInterval = () => {
		clearInterval(intervalRef.current);
		setIsIntervalRunning(false);
	  };
	  
	  const handleTimeChange = (newValue) => {
		if (newValue) {
		  const formattedTime = newValue.format('HH:mm');
		  setSelectedTime(formattedTime);
		} else {
		  setSelectedTime(null);
		}
	  };
	  
	  const fetchData = async () => {
		try {
		  const timeString = selectedTime ? selectedTime : '';
		  console.log("Time string:", timeString);
		  const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/getNiftyBackTester`, {
			headers: {
			  'Content-Type': 'application/json'
			},
			params: {
			  expiry_date: selectedExpiryDate,
			  date: selectedDate,
			  time: timeString
			}
		  });
	  
		  setNiftyFilterDb(response.data.data);
		  setSpotPrice(response.data.data[0]?.spot_price);
		  console.log("spot ",spotPrice);
		  const remainder = response.data.data[0]?.spot_price % 50;
		  const lowerRoundedValue = response.data.data[0]?.spot_price - remainder;
		  const upperRoundedValue = lowerRoundedValue + 50;
		  const adjustedLower = Math.floor(lowerRoundedValue / 50) * 50;
		  const adjustedUpper = Math.ceil(upperRoundedValue / 50) * 50;
	  
		  setUpperCe(adjustedUpper);
		  setLowerCe(adjustedLower);
	  
		  console.log("Filtered data:", response.data.data);
		} catch (error) {
		  console.error("Error fetching Nifty Db data fetch:", error);
		}
	  };
	  
	  useEffect(() => {
		fetchExpiryDate();
	  }, []);	  

	  const [niftyData, setNiftyData] = useState([]);
    const handleCellClick = async (strickPrice, optionType) => {
      try {
          const response = await fetchNiftyData(strickPrice, optionType);
          setNiftyData(response.data.data);
      } catch (error) {
          console.error("Error handling cell click:", error);
      }
  };
  
	  
	  console.log(selectedTime);
    const fetchNiftyData = async (strickPrice, optionType) => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/getNiftyBackTesterChartData`, {
              params: {
                  strickPrice,
                  optionType,
                  date: selectedDate,
                  time: selectedTime
              }
          });
          console.log("chart", strickPrice, optionType, selectedDate, selectedTime);
          console.log("Response:", response.data);
          return { data: response.data };
      } catch (error) {
          console.error("Error fetching Nifty data:", error);
          throw error;
      }
  };
  
  console.log(niftyData);  
  function formatNumber(num) {
    const suffixes = ['', 'K', 'M', 'B'];

    // Check if the number is negative
    const isNegative = num < 0;
    num = Math.abs(num);

    if (num < 1000) {
        return isNegative ? "-" + num.toFixed(0) : num.toFixed(0); // Return as it is if less than 1000
    }

    const suffixIndex = Math.floor(Math.log10(num) / 3);

    const scaledNum = num / Math.pow(10, suffixIndex * 3);

    let formattedNum = scaledNum.toFixed(2);

    if (suffixIndex < suffixes.length) {
        formattedNum += suffixes[suffixIndex];
    } else {
        formattedNum += '+';
    }

    return isNegative ? "-" + formattedNum : formattedNum;
}
const currentTime = new Date();
const [targetSpotPrice, setTargetSpotPrice] = useState('');
const [delta, setDelta] = useState('');
const [ltp, setLTP] = useState('');

const handleChange = (e) => {
  const value = e.target.value.trim();
  setTargetSpotPrice(value);
};
const calculateValues = () => {
  if (!targetSpotPrice || targetSpotPrice.trim() === '') {
    return { buyPrice: '', target: '', sl: '' };
  }
  const deltaAbs = Math.abs(delta);
  console.log("delta",deltaAbs);
  const buyPrice = ltp + ((parseFloat(targetSpotPrice) - spotPrice) * parseFloat(deltaAbs));
  const target = buyPrice + (50 * parseFloat(deltaAbs));
  const sl = buyPrice - (20 * parseFloat(deltaAbs));
  return { buyPrice, target, sl };
};

const calculateValuesPe = () => {
  if (!targetSpotPrice || targetSpotPrice.trim() === '') {
    return { buyPrice: '', target: '', sl: '' };
  }
  const deltaAbs = Math.abs(delta);
  console.log("delta",deltaAbs);
  const buyPricePe = ltp - ((parseFloat(targetSpotPrice) - spotPrice) * parseFloat(deltaAbs));
  const targetPE = buyPricePe + (50 * parseFloat(deltaAbs));
  const slPE = buyPricePe - (20 * parseFloat(deltaAbs));
  return { buyPricePe, targetPE, slPE };
};
const { buyPrice, target, sl } = calculateValues();
const { buyPricePe, targetPE, slPE} = calculateValuesPe()
  const [strickPrice, setStrickPrice] = useState(null);
  const renderRows = () => {
    const rows = [];
      if (niftyFilterDb.length > 0) {
        let maxCE_OI = 0;
        let maxCE_volume = 0;
        let maxCE_oiChange = 0;
        let maxPE_oiChange = 0;
        let maxPE_OI = 0;
        let maxPE_volume = 0;
     
       // Find the maximum value of CE oi and volume within the upper range
       for (let i = -9; i <= 0; i++) {
        const ce_strike_price = upperCe - i * 50;
        const filterItem = niftyFilterDb.filter(item => item.strick_price === ce_strike_price);
        const ceData = filterItem;
        const peData = filterItem;
            if (ceData.length > 0 && ceData[0].ce_oi > maxCE_OI) {
              maxCE_OI = ceData[0].ce_oi;
            }
            if (ceData.length > 0 && ceData[0].ce_volume > maxCE_volume) {
                maxCE_volume = ceData[0].ce_volume;
            }
            if (ceData.length > 0 && ceData[0].ce_oi_change > maxCE_oiChange) {
              maxCE_oiChange = ceData[0].ce_oi_change;
            }
            if (peData.length > 0 && peData[0].pe_oi > maxPE_OI) {
                maxPE_OI = peData[0].pe_oi;
            }
            if (peData.length > 0 && peData[0].pe_volume > maxPE_volume) {
                maxPE_volume = peData[0].pe_volume;
            }
            if (peData.length > 0 && peData[0].pe_oi_change > maxPE_oiChange) {
              maxPE_oiChange = peData[0].pe_oi_change;
          }
      }
      
      // Find the maximum value of CE oi and volume within the lower range
      for (let i = 0; i <= 9; i++) {
        const ce_strike_price = lowerCe - i * 50;
        const filterItem = niftyFilterDb.filter(item => item.strick_price === ce_strike_price);
        const ceData = filterItem;
        const peData = filterItem;
            if (ceData.length > 0 && ceData[0].ce_oi > maxCE_OI) {
              maxCE_OI = ceData[0].ce_oi;
            }
            if (ceData.length > 0 && ceData[0].ce_volume > maxCE_volume) {
                maxCE_volume = ceData[0].ce_volume;
            }
            if (ceData.length > 0 && ceData[0].ce_oi_change > maxCE_oiChange) {
              maxCE_oiChange = ceData[0].ce_oi_change;
            }
            if (peData.length > 0 && peData[0].pe_oi > maxPE_OI) {
                maxPE_OI = peData[0].pe_oi;
            }
            if (peData.length > 0 && peData[0].pe_volume > maxPE_volume) {
                maxPE_volume = peData[0].pe_volume;
            }
            if (peData.length > 0 && peData[0].pe_oi_change > maxPE_oiChange) {
              maxPE_oiChange = peData[0].pe_oi_change;
          }
      }
          // Render rows for the upper range
          for (let i = -9; i <= 0; i++) {
            const ce_strike_price = upperCe - i * 50;
            const filterItem = niftyFilterDb.filter(item => item.strick_price === ce_strike_price);
            const ceData = filterItem;
            const peData = filterItem;
            let cePercent;
            let ceVolumePercent
            let pePercent
            let peVolumePercent
            let ceOiChange
            let peOiChange
              // Calculate percentage based on the maximum value
              if (ceData.length > 0) {
                cePercent = maxCE_OI !== 0 ? (ceData[0].ce_oi / maxCE_OI) * 100 : 0;
                ceVolumePercent = maxCE_volume !== 0 ? (ceData[0].ce_volume / maxCE_volume) * 100 : 0;
                ceOiChange = maxCE_oiChange !== 0 ? Math.max(0,ceData[0].ce_oi_change / maxCE_oiChange) * 100 : 0;
            }
        
            if (peData.length > 0) {
                pePercent = maxPE_OI !== 0 ? (peData[0].pe_oi / maxPE_OI) * 100 : 0;
                peVolumePercent = maxPE_volume !== 0 ? (peData[0].pe_volume / maxPE_volume) * 100 : 0;
                peOiChange = maxPE_oiChange !== 0 ? Math.max(0,peData[0].pe_oi_change / maxPE_oiChange) * 100 : 0;
            }
          
              let cePercentStyle = {};
              let pePercentStyle = {};
              let cePercentStyleVolume = {};
              let pePercentStyleVolume = {};
              let cePercentStyleOiChange = {};
              let pePercentStyleOiChange = {};
          
              if (cePercent === 100) {
                cePercentStyle = { backgroundColor: "#b22c01",color: "#fff",textAlign: "center" };
              } else if (cePercent >= 95 && cePercent < 100) {
                  cePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center"  };
              } else if (cePercent >= 75 && cePercent < 95) {
                  cePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
              } else {
                  cePercentStyle = { color: 'inherit' }; // Default color
              }
        
              // Set styles based on PE percentage
              if (pePercent === 100) {
                  pePercentStyle = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
              } else if (pePercent >= 95 && pePercent < 100) {
                  pePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
              } else if (pePercent >= 75 && pePercent < 95) {
                  pePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center"};
              } else {
                  pePercentStyle = { color: 'inherit' }; // Default color
              }
        
              // Set styles based on CE volume percentage
              if (ceVolumePercent === 100) {
                  cePercentStyleVolume = {backgroundColor: "#b22c01",color: "#fff",textAlign: "center"  };
              } else if (ceVolumePercent >= 95 && ceVolumePercent < 100) {
                  cePercentStyleVolume = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
              } else if (ceVolumePercent >= 75 && ceVolumePercent < 95) {
                  cePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
              } else {
                  cePercentStyleVolume = { color: 'inherit' }; // Default color
              }
        
              // Set styles based on PE volume percentage
              if (peVolumePercent === 100) {
                  pePercentStyleVolume = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
              } else if (peVolumePercent >= 95 && peVolumePercent < 100) {
                  pePercentStyleVolume = { backgroundColor: "#F6BE00",color: "#000",textAlign: "center"};
              } else if (peVolumePercent >= 75 && peVolumePercent < 95) {
                  pePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
              } else {
                  pePercentStyleVolume = { color: 'inherit' }; // Default color
              }
              if (ceOiChange === 100) {
                cePercentStyleOiChange = { backgroundColor: "#b22c01",color: "#fff",textAlign: "center" };
              } else if (ceOiChange >= 95 && ceOiChange < 100) {
                cePercentStyleOiChange = {backgroundColor: "#e3d358",color: "#000",textAlign: "center"  };
              } else if (ceOiChange >= 75 && ceOiChange < 95) {
                cePercentStyleOiChange = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
              } else {
                cePercentStyleOiChange = { color: 'inherit' }; // Default color
              }
        
              // Set styles based on PE percentage
              if (peOiChange === 100) {
                  pePercentStyleOiChange = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
              } else if (peOiChange >= 95 && peOiChange < 100) {
                pePercentStyleOiChange = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
              } else if (peOiChange >= 75 && peOiChange < 95) {
                pePercentStyleOiChange = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center"};
              } else {
                pePercentStyleOiChange = { color: 'inherit' }; // Default color
              }
              const isWithinRange = (vwap, lastPrice) => {
                const lowerBound = 0.97 * lastPrice;
                const upperBound = 1.03 * lastPrice;
                return vwap >= lowerBound && vwap <= upperBound;
              };

          const ceVwapColorStyle = ceData[0] && isWithinRange(ceData[0].ce_vwap, ceData[0].ce_ltp) ? { 
            background: `linear-gradient(to bottom, #FF9800, #FFD54F)`, 
            color: '#000', 
            textAlign: 'center'  
          } : {};
        
          const peVwapColorStyle = peData[0] && isWithinRange(peData[0].pe_vwap, peData[0].pe_ltp) ? { 
            background: `linear-gradient(to bottom, #E65C19, #FF7F45)`, 
            color: '#000', 
            textAlign: 'center' 
          } : {};
          const isITM = (strikePrice) => spotPrice > strikePrice;
          const isITMPE = (strickPrice) => spotPrice < strickPrice
          // Apply conditional styles based on whether the strike price is ITM
          const ITMLTP = isITM(upperCe - i * 50) ? {
            background: `linear-gradient(to bottom, #78AA7A, #FFFFFF)`,
            color: '#000',
            textAlign: 'center'
          } : {};
          const ITMLTPPE = isITMPE(upperCe - i * 50) ? {
              background: `linear-gradient(to bottom, #FFFFFF, #78AA7A)`,
              color: '#000',
              textAlign: 'center'
          } : {};
		
          rows.push(
            <tr key={i} >
                <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleIV" role="button">{ceData.length > 0 ? ceData[0].ce_iv || 0 : 0}<br/><span style={ceData[0] && ceData[0].ce_delta >= 0.7 && ceData[0].ce_delta <= 0.9 ? { color: 'green' } : {}}>
                  {ceData[0] && ceData[0].ce_delta !== undefined ? ceData[0].ce_delta : 0}
                /{ceData.length > 0 ? ceData[0].gamma || 0 : 0}</span></td>
                <td className="text-right-td" style={{...cePercentStyleOiChange, ...(ceOiChange  <= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button" onClick={() => {handleCellClick(upperCe - i * 50, 'CE'); setOptionType("CE");}}>
                  {ceOiChange !== undefined ? ceOiChange.toFixed(2) + "%" : "N/A"}<br/>
                  <span style={ceData[0] && ceData[0].ce_oi_change <= 0 ? { color: 'red' } : {}}>
                    {formatNumber(ceData[0] && ceData[0].ce_oi_change !== undefined ? ceData[0].ce_oi_change : 0) || 0}
                  </span>
                </td>
                <td className="text-right-td" style={{...cePercentStyle, ...(cePercent <= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggle" role="button" onClick={() => {handleCellClick(upperCe - i * 50, 'CE'); setOptionType("CE");}}>
                  {cePercent !== undefined ? cePercent.toFixed(2) + "%" : "N/A"}<br/>
                  <span>{formatNumber(ceData[0] && ceData[0].ce_oi !== undefined ? ceData[0].ce_oi : 0) || 0}</span>
                </td>
                <td className="text-right-td" style={{...cePercentStyleVolume, ...(ceVolumePercent<= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleVolumeCE" role="button" onClick={() => setStrickPrice(upperCe - i * 50)}>
                  {ceVolumePercent!== undefined ? ceVolumePercent.toFixed(2) + "%" : "N/A"}<br/>
                  <span>{formatNumber(ceData[0] && ceData[0].ce_volume !== undefined ? ceData[0].ce_volume : 0) || 0}</span>
                </td>
                <td className="text-right-td" style={ceVwapColorStyle} data-bs-toggle="modal" href="#exampleModalToggleVWAP" role="button" onClick={() => {handleCellClick(upperCe - i * 50, 'CE'); setOptionType("CE");}}>{ceData[0] && ceData[0].ce_vwap !== undefined ? ceData[0].ce_vwap : 0}</td>
                <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChg" role="button" onClick={() => {setDelta(ceData[0].ce_delta);setLTP(ceData[0].ce_ltp);setOptionType("PE");setStrickPrice(upperCe - i * 50);setTargetSpotPrice("")}} style={ITMLTP}>
                  {ceData[0] && ceData[0].ce_ltp !== undefined ? ceData[0].ce_ltp : 0}<br/>
                  <span style={ceData[0] && ceData[0].ce_ltp_chg <= 0 ? { color: 'red' } : {}}>
                    {ceData[0] && ceData[0].ce_ltp_chg !== undefined ? ceData[0].ce_ltp_chg : 0}
                  </span>
                  <span style={ceData[0] && ceData[0].ce_ltp_tv <= 0 ? { color: 'red' } : {}}>
                    ({ceData[0] && ceData[0].ce_ltp_tv !== undefined ? ceData[0].ce_ltp_tv : 0})
                  </span>
                </td>
                <td className="text-center-td">{upperCe - i * 50}<br/><span>0.11</span></td>
                <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChgPE" role="button" onClick={() => {setDelta(peData[0].pe_delta);setLTP(peData[0].pe_ltp);setOptionType("PE");setStrickPrice(upperCe - i * 50);setTargetSpotPrice("")}} style={ITMLTPPE }>
                  {peData[0] && peData[0].pe_ltp !== undefined ? peData[0].pe_ltp : 0}<br/>
                  <span style={peData[0] && peData[0].pe_ltp_chg <= 0 ? { color: 'red' } : {}}>
                    {peData[0] && peData[0].pe_ltp_chg !== undefined ? peData[0].pe_ltp_chg : 0}
                  </span>
                  <span style={peData[0] && peData[0].pe_ltp_tv <= 0 ? { color: 'red' } : {}}>
                    ({peData[0] && peData[0].pe_ltp_tv !== undefined ? peData[0].pe_ltp_tv : 0})
                  </span>
                </td>
                <td className="text-left-td" style={peVwapColorStyle} data-bs-toggle="modal" href="#exampleModalToggleVWAP" role="button" onClick={() => {handleCellClick(upperCe - i * 50, 'PE'); setOptionType("PE");}}>{peData[0] && peData[0].pe_vwap !== undefined ? peData[0].pe_vwap : 0}</td>
                <td className="text-left-td" style={{...pePercentStyleVolume, ...(peVolumePercent<= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleVolumePE" role="button"  onClick={() => {
                const ceStrike = upperCe - i * 50 + 50; // Calculate the CE side strike one level higher 
                const ceDataIndex = niftyFilterDb.filter(item => item.strick_price === ceStrike)
                setNextTsl2(ceDataIndex[0].tsl2); // Set nextTsl2 in the state
                setStrickPrice(upperCe - i * 50); // Set the selected PE side strike            
                }}>
                  {peVolumePercent!== undefined ? peVolumePercent.toFixed(2) + "%" : "N/A"}<br/>
                  <span style={peData[0] && peData[0].pe_volume <= 0 ? { color: 'red' } : {}}>
                    {formatNumber(peData[0] && peData[0].pe_volume !== undefined ? peData[0].pe_volume : 0) || 0}
                  </span>
                </td>
      
                <td 
                  className="text-left-td" 
                  style={{...pePercentStyle, ...(pePercent<= 0 ? { color: 'red' } : {})}}
                  data-bs-toggle="modal" 
                  href="#exampleModalToggle" 
                  role="button" 
                  onClick={() => {
                    handleCellClick(upperCe - i * 50, 'PE');
                    setOptionType("PE");
                  }}
                >
                  {pePercent !== undefined ? pePercent.toFixed(2) + "%" : "N/A"}
                  <br/>
                  <span style={peData[0] && peData[0].pe_oi <= 0 ? { color: 'red' } : {}}>
                    {formatNumber(peData[0] && peData[0].pe_oi !== undefined ? peData[0].pe_oi : 0) || 0}
                  </span>
                </td>
      
                <td className="text-left-td" style={{...pePercentStyleOiChange, ...(peOiChange<= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button" onClick={() => {handleCellClick(upperCe - i * 50, 'PE'); setOptionType("PE");}}>
                  {peOiChange!== undefined ? peOiChange.toFixed(2) + "%" : "N/A"}<br/>
                  <span style={peData[0] && peData[0].pe_oi_change <= 0 ? { color: 'red' } : {}}>
                    {formatNumber(peData[0] && peData[0].pe_oi_change !== undefined ? peData[0].pe_oi_change : 0) || 0}
                  </span>
                </td>
                <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleIV" role="button">{peData.length > 0 ? peData[0].pe_iv || 0 : 0}<br/><span style={peData[0] && peData[0].pe_delta >= -0.9 && peData[0].pe_delta <= -0.7 ? { color: 'green' } : {}}>{peData.length > 0 ? peData[0].pe_delta || 0 : 0}/{peData.length > 0 ? peData[0].pe_gamma || 0 : 0}</span></td>
      
            </tr>
          );
          }
    }  else {
      console.log("else else else");
        return <tr><td colSpan="13">Loading...</td></tr>;
    }
      return rows;
};


const renderLower = () => {
  const rows = [];
  if (niftyFilterDb.length > 0) {
    let maxCE_OI = 0;
    let maxCE_volume = 0;
    let maxCE_oiChange = 0;
    let maxPE_oiChange = 0;
    let maxPE_OI = 0;
    let maxPE_volume = 0;
 
   // Find the maximum value of CE oi and volume within the upper range
   for (let i = -9; i <= 0; i++) {
    const ce_strike_price = upperCe - i * 50;
    const filterItem = niftyFilterDb.filter(item => item.strick_price === ce_strike_price);
    const ceData = filterItem;
    const peData = filterItem;
        if (ceData.length > 0 && ceData[0].ce_oi > maxCE_OI) {
          maxCE_OI = ceData[0].ce_oi;
        }
        if (ceData.length > 0 && ceData[0].ce_volume > maxCE_volume) {
            maxCE_volume = ceData[0].ce_volume;
        }
        if (ceData.length > 0 && ceData[0].ce_oi_change > maxCE_oiChange) {
          maxCE_oiChange = ceData[0].ce_oi_change;
        }
        if (peData.length > 0 && peData[0].pe_oi > maxPE_OI) {
            maxPE_OI = peData[0].pe_oi;
        }
        if (peData.length > 0 && peData[0].pe_volume > maxPE_volume) {
            maxPE_volume = peData[0].pe_volume;
        }
        if (peData.length > 0 && peData[0].pe_oi_change > maxPE_oiChange) {
          maxPE_oiChange = peData[0].pe_oi_change;
      }
  }
  
  // Find the maximum value of CE oi and volume within the lower range
  for (let i = 0; i <= 9; i++) {
    const ce_strike_price = lowerCe - i * 50;
    const filterItem = niftyFilterDb.filter(item => item.strick_price === ce_strike_price);
    const ceData = filterItem;
    const peData = filterItem;
        if (ceData.length > 0 && ceData[0].ce_oi > maxCE_OI) {
          maxCE_OI = ceData[0].ce_oi;
        }
        if (ceData.length > 0 && ceData[0].ce_volume > maxCE_volume) {
            maxCE_volume = ceData[0].ce_volume;
        }
        if (ceData.length > 0 && ceData[0].ce_oi_change > maxCE_oiChange) {
          maxCE_oiChange = ceData[0].ce_oi_change;
        }
        if (peData.length > 0 && peData[0].pe_oi > maxPE_OI) {
            maxPE_OI = peData[0].pe_oi;
        }
        if (peData.length > 0 && peData[0].pe_volume > maxPE_volume) {
            maxPE_volume = peData[0].pe_volume;
        }
        if (peData.length > 0 && peData[0].pe_oi_change > maxPE_oiChange) {
          maxPE_oiChange = peData[0].pe_oi_change;
      }
  }
      // Render rows for the upper range
      for (let i = 0; i <= 9; i++) {
        const ce_strike_price = lowerCe - i * 50;
        const filterItem = niftyFilterDb.filter(item => item.strick_price === ce_strike_price);
        const ceData = filterItem;
        const peData = filterItem;
    
        let cePercent;
        let ceVolumePercent
        let pePercent
        let peVolumePercent
        let ceOiChange
        let peOiChange
          // Calculate percentage based on the maximum value
          if (ceData.length > 0) {
            cePercent = maxCE_OI !== 0 ? (ceData[0].ce_oi / maxCE_OI) * 100 : 0;
            ceVolumePercent = maxCE_volume !== 0 ? (ceData[0].ce_volume / maxCE_volume) * 100 : 0;
            ceOiChange = maxCE_oiChange !== 0 ? Math.max(0,ceData[0].ce_oi_change / maxCE_oiChange) * 100 : 0;
        }
    
        if (peData.length > 0) {
            pePercent = maxPE_OI !== 0 ? (peData[0].pe_oi / maxPE_OI) * 100 : 0;
            peVolumePercent = maxPE_volume !== 0 ? (peData[0].pe_volume / maxPE_volume) * 100 : 0;
            peOiChange = maxPE_oiChange !== 0 ? Math.max(0,peData[0].pe_oi_change / maxPE_oiChange) * 100 : 0;
        }
      
          let cePercentStyle = {};
          let pePercentStyle = {};
          let cePercentStyleVolume = {};
          let pePercentStyleVolume = {};
          let cePercentStyleOiChange = {};
          let pePercentStyleOiChange = {};
      
          if (cePercent === 100) {
            cePercentStyle = { backgroundColor: "#b22c01",color: "#fff",textAlign: "center" };
          } else if (cePercent >= 95 && cePercent < 100) {
              cePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center"  };
          } else if (cePercent >= 75 && cePercent < 95) {
              cePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
          } else {
              cePercentStyle = { color: 'inherit' }; // Default color
          }
    
          // Set styles based on PE percentage
          if (pePercent === 100) {
              pePercentStyle = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
          } else if (pePercent >= 95 && pePercent < 100) {
              pePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
          } else if (pePercent >= 75 && pePercent < 95) {
              pePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center"};
          } else {
              pePercentStyle = { color: 'inherit' }; // Default color
          }
    
          // Set styles based on CE volume percentage
          if (ceVolumePercent === 100) {
              cePercentStyleVolume = {backgroundColor: "#b22c01",color: "#fff",textAlign: "center"  };
          } else if (ceVolumePercent >= 95 && ceVolumePercent < 100) {
              cePercentStyleVolume = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
          } else if (ceVolumePercent >= 75 && ceVolumePercent < 95) {
              cePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
          } else {
              cePercentStyleVolume = { color: 'inherit' }; // Default color
          }
    
          // Set styles based on PE volume percentage
          if (peVolumePercent === 100) {
              pePercentStyleVolume = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
          } else if (peVolumePercent >= 95 && peVolumePercent < 100) {
              pePercentStyleVolume = { backgroundColor: "#F6BE00",color: "#000",textAlign: "center"};
          } else if (peVolumePercent >= 75 && peVolumePercent < 95) {
              pePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
          } else {
              pePercentStyleVolume = { color: 'inherit' }; // Default color
          }
          if (ceOiChange === 100) {
            cePercentStyleOiChange = { backgroundColor: "#b22c01",color: "#fff",textAlign: "center" };
          } else if (ceOiChange >= 95 && ceOiChange < 100) {
            cePercentStyleOiChange = {backgroundColor: "#e3d358",color: "#000",textAlign: "center"  };
          } else if (ceOiChange >= 75 && ceOiChange < 95) {
            cePercentStyleOiChange = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
          } else {
            cePercentStyleOiChange = { color: 'inherit' }; // Default color
          }
    
          // Set styles based on PE percentage
          if (peOiChange === 100) {
              pePercentStyleOiChange = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
          } else if (peOiChange >= 95 && peOiChange < 100) {
            pePercentStyleOiChange = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
          } else if (peOiChange >= 75 && peOiChange < 95) {
            pePercentStyleOiChange = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center"};
          } else {
            pePercentStyleOiChange = { color: 'inherit' }; // Default color
          }
          const isWithinRange = (vwap, lastPrice) => {
            const lowerBound = 0.97 * lastPrice;
            const upperBound = 1.03 * lastPrice;
            return vwap >= lowerBound && vwap <= upperBound;
        };

        const ceVwapColorStyle = ceData[0] && isWithinRange(ceData[0].ce_vwap, ceData[0].ce_ltp) ? { 
          background: `linear-gradient(to bottom, #FF9800, #FFD54F)`, 
        color: '#000', 
        textAlign: 'center' 
      } : {};
      
      const peVwapColorStyle = peData[0] && isWithinRange(peData[0].pe_vwap, peData[0].pe_ltp) ? { 
        background: `linear-gradient(to bottom, #E65C19, #FF7F45)`, 
        color: '#000', 
        textAlign: 'center'
      } : {};
      // Render rows for the lower range
      const isITM = (strikePrice) => spotPrice > strikePrice;
      const isITMPE = (strickPrice) => spotPrice < strickPrice
      // Apply conditional styles based on whether the strike price is ITM
      const ITMLTP = isITM(lowerCe - i * 50) ? {
        background: `linear-gradient(to bottom, #78AA7A, #FFFFFF)`,
        color: '#000',
        textAlign: 'center'
      } : {};
      const ITMLTPPE = isITMPE(lowerCe - i * 50) ? {
        background: `linear-gradient(to bottom, #FFFFFF, #78AA7A)`,
        color: '#000',
        textAlign: 'center'
    } : {};

      rows.push(
        <tr key={i} >
            <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleIV" role="button">{ceData.length > 0 ? ceData[0].ce_iv || 0 : 0}<br/><span style={ceData[0] && ceData[0].ce_delta >= 0.7 && ceData[0].ce_delta <= 0.9 ? { color: 'green' } : {}}>
              {ceData[0] && ceData[0].ce_delta !== undefined ? ceData[0].ce_delta : 0}
            /{ceData.length > 0 ? ceData[0].gamma || 0 : 0}</span></td>
            <td className="text-left-td" style={{...cePercentStyleOiChange, ...(ceOiChange  <= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button" onClick={() => {handleCellClick(lowerCe - i * 50, 'CE'); setOptionType("CE");}}>
              {ceOiChange !== undefined ? ceOiChange.toFixed(2) + "%" : "N/A"}<br/>
              <span style={ceData[0] && ceData[0].ce_oi_change <= 0 ? { color: 'red' } : {}}>
                {formatNumber(ceData[0] && ceData[0].ce_oi_change !== undefined ? ceData[0].ce_oi_change : 0) || 0}
              </span>
            </td>
            <td className="text-left-td" style={{...cePercentStyle, ...(cePercent <= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggle" role="button" onClick={() => {handleCellClick(lowerCe - i * 50, 'CE'); setOptionType("CE");}}>
              {cePercent !== undefined ? cePercent.toFixed(2) + "%" : "N/A"}<br/>
              <span>{formatNumber(ceData[0] && ceData[0].ce_oi !== undefined ? ceData[0].ce_oi : 0) || 0}</span>
            </td>
            <td className="text-left-td" style={{...cePercentStyleVolume, ...(ceVolumePercent<= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleVolumeCE" role="button" onClick={() => setStrickPrice(lowerCe - i * 50)}>
              {ceVolumePercent!== undefined ? ceVolumePercent.toFixed(2) + "%" : "N/A"}<br/>
              <span>{formatNumber(ceData[0] && ceData[0].ce_volume !== undefined ? ceData[0].ce_volume : 0) || 0}</span>
            </td>
            <td className="text-left-td" style={ceVwapColorStyle} data-bs-toggle="modal" href="#exampleModalToggleVWAP" role="button" onClick={() => {handleCellClick(lowerCe - i * 50, 'CE'); setOptionType("CE");}}>{ceData[0] && ceData[0].ce_vwap !== undefined ? ceData[0].ce_vwap : 0}</td>
            <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChg" role="button" onClick={() => {setDelta(ceData[0].ce_delta);setLTP(ceData[0].ce_ltp);setOptionType("PE");setStrickPrice(lowerCe - i * 50);setTargetSpotPrice("")}} style={ITMLTP}>
              {ceData[0] && ceData[0].ce_ltp !== undefined ? ceData[0].ce_ltp : 0}<br/>
              <span style={ceData[0] && ceData[0].ce_ltp_chg <= 0 ? { color: 'red' } : {}}>
                {ceData[0] && ceData[0].ce_ltp_chg !== undefined ? ceData[0].ce_ltp_chg : 0}
              </span>
              <span style={ceData[0] && ceData[0].ce_ltp_tv <= 0 ? { color: 'red' } : {}}>
                ({ceData[0] && ceData[0].ce_ltp_tv !== undefined ? ceData[0].ce_ltp_tv : 0})
              </span>
            </td>
            <td className="text-center-td">{lowerCe - i * 50}<br/><span>0.11</span></td>
            <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChgPE" role="button" onClick={() => {setDelta(peData[0].pe_delta);setLTP(peData[0].pe_ltp);setOptionType("PE");setStrickPrice(lowerCe - i * 50);setTargetSpotPrice("")}} style={ITMLTPPE}>
              {peData[0] && peData[0].pe_ltp !== undefined ? peData[0].pe_ltp : 0}<br/>
              <span style={peData[0] && peData[0].pe_ltp_chg <= 0 ? { color: 'red' } : {}}>
                {peData[0] && peData[0].pe_ltp_chg !== undefined ? peData[0].pe_ltp_chg : 0}
              </span>
              <span style={peData[0] && peData[0].pe_ltp_tv <= 0 ? { color: 'red' } : {}}>
                ({peData[0] && peData[0].pe_ltp_tv !== undefined ? peData[0].pe_ltp_tv : 0})
              </span>
            </td>
            <td className="text-right-td" style={peVwapColorStyle} data-bs-toggle="modal" href="#exampleModalToggleVWAP" role="button" onClick={() => {handleCellClick(lowerCe - i * 50, 'PE'); setOptionType("PE");}}>{peData[0] && peData[0].pe_vwap !== undefined ? peData[0].pe_vwap : 0}</td>
            <td className="text-right-td" style={{...pePercentStyleVolume, ...(peVolumePercent<= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleVolumePE" role="button" onClick={() => {
                const ceStrike = lowerCe - i * 50 + 50; // Calculate the CE side strike one level higher 
                const ceDataIndex = niftyFilterDb.filter(item => item.strick_price === ceStrike)
                setNextTsl2(ceDataIndex[0].tsl2); // Set nextTsl2 in the state
                setStrickPrice(lowerCe - i * 50); // Set the selected PE side strike
                            
                }}>
              {peVolumePercent!== undefined ? peVolumePercent.toFixed(2) + "%" : "N/A"}<br/>
              <span style={peData[0] && peData[0].pe_volume <= 0 ? { color: 'red' } : {}}>
                {formatNumber(peData[0] && peData[0].pe_volume !== undefined ? peData[0].pe_volume : 0) || 0}
              </span>
            </td>
  
            <td 
              className="text-right-td" 
              style={{...pePercentStyle, ...(pePercent<= 0 ? { color: 'red' } : {})}}
              data-bs-toggle="modal" 
              href="#exampleModalToggle" 
              role="button" 
              onClick={() => {
                handleCellClick(lowerCe - i * 50, 'PE');
                setOptionType("PE");
              }}
            >
              {pePercent !== undefined ? pePercent.toFixed(2) + "%" : "N/A"}
              <br/>
              <span style={peData[0] && peData[0].pe_oi <= 0 ? { color: 'red' } : {}}>
                {formatNumber(peData[0] && peData[0].pe_oi !== undefined ? peData[0].pe_oi : 0) || 0}
              </span>
            </td>
  
            <td className="text-right-td" style={{...pePercentStyleOiChange, ...(peOiChange<= 0 ? { color: 'red' } : {})}} data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button" onClick={() => {handleCellClick(lowerCe - i * 50, 'PE'); setOptionType("PE");}}>
              {peOiChange!== undefined ? peOiChange.toFixed(2) + "%" : "N/A"}<br/>
              <span style={peData[0] && peData[0].pe_oi_change <= 0 ? { color: 'red' } : {}}>
                {formatNumber(peData[0] && peData[0].pe_oi_change !== undefined ? peData[0].pe_oi_change : 0) || 0}
              </span>
            </td>
            <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleIV" role="button">{peData.length > 0 ? peData[0].pe_iv || 0 : 0}<br/><span style={peData[0] && peData[0].pe_delta >= -0.9 && peData[0].pe_delta <= -0.7 ? { color: 'green' } : {}}>{peData.length > 0 ? peData[0].pe_delta || 0 : 0}/{peData.length > 0 ? peData[0].pe_gamma || 0 : 0}</span></td>
  
        </tr>
      );
      }
} else {
  console.log("else else else");
    return <tr><td colSpan="13">Loading...</td></tr>;
}
  return rows;
};


let displayTime;

  if (niftyFilterDb && niftyFilterDb.length > 0) {
    displayTime = niftyFilterDb[1]?.nifty_time_ce || `${new Date().toISOString().slice(0, 10)} 15:30:00`;
    console.log("else", displayTime);
  } else {
    displayTime = `${new Date().toISOString().slice(0, 10)} 15:30:00`;
    console.log("else", displayTime);
  }

const [optionType, setOptionType] = useState('CE'); // Initially set to CE

const filteredData = optionType === 'CE' ? 
niftyData.filter(item => item.ce_oi_pr !== null) : 
niftyData.filter(item => item.pe_oi_pr !== null);

console.log("nifty filter db",filteredData);
  const spotPriceDb = niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].spot_price;
  let targetPEVolume
  let targetCEVolume
  if (Array.isArray(niftyFilterDb)) {
      // Find the PE item with volumePercentage equal to 100
      targetCEVolume = niftyFilterDb.find(item => item.ce_volume_pr === 100);
      targetPEVolume =niftyFilterDb.find(item => item.pe_volume_pr === 100);
  } else {
      console.error("PE array is not defined or not an array.");
  }
  return (
	<div>
      <div className="wrapper">
     {/* <!--start page wrapper --> */}
    <div className="page-wrapper">
	<div className="container" id="main">
    <div className="row" id="rowitem">
        <div className="col-md-8"> 
            <div className="row">
				<div className="col-md-3 col-sm-3 text-center">
					<label style={{ marginTop: '10px' }} htmlFor="">Expiry Date</label>
					<div className="dropdown">
						<select
							className="btn btn-light dropdown-toggle"
							aria-labelledby="dropdownMenuButton1"
							value={selectedExpiryDate}
							onChange={(e) => {
								setSelectedExpiryDate(e.target.value);
								fetchDate(e.target.value);
							}}
						>
							<option value="">Select Expiry</option>
							{expiryDate.map((item, index) => (
								<option key={index} value={item.expiry_date}>{item.expiry_date}</option>
							))}
						</select>
					</div>
				</div>
				<div className="col-md-3 col-sm-3 text-center">
					<label style={{ marginTop: '10px' }} htmlFor="">Date</label>
					<div className="dropdown">
						<select
							className="btn btn-light dropdown-toggle"
							aria-labelledby="dropdownMenuButton1"
							value={selectedDate}
							onChange={(e) => setSelectedDate(e.target.value)}
						>
							<option value="">Select Date</option>
							{date && Array.isArray(date) ? date.map((item, index) => (
								<option key={index} value={item}>{item}</option>
							)) : null}
						</select>
					</div>
				</div>
				 <div className="col-lg-2 col-md-3 text-center mb-3 mb-md-0">
					<label style={{ marginTop: '10px' }} htmlFor="">Time</label>
					<Space wrap>
					<TimePicker
						defaultValue={dayjs('00:00', 'HH:mm')}
						format="HH:mm"
						hideDisabledOptions
						disabledTime={disabledTime}
						value={selectedTime ? dayjs(selectedTime, 'HH:mm') : null}
						onChange={handleTimeChange}
					/>
					</Space>
				</div>
				<div className="col-md-3 col-sm-3 text-center">
                	<button style={{ marginTop: '37px' }} className="btn btn-success" onClick={fetchData}>Fetch</button>
                </div>
            </div>
        </div>
		<div className="col-md-4">
		<div className="row">
      <div className="col-md-6 mt-2 text-center">
        <label className="mb-0 text-center" htmlFor="">Interval</label>
        <div className="btn-group d-flex" role="group" aria-label="Basic radio toggle button group" style={{ width: '100%' }}>
          <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" defaultChecked onClick={() => handleIntervalClick(1)} />
          <label className="btn btn-primary rounded-start" htmlFor="btnradio3" >1 Min</label>

          <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" onClick={() => handleIntervalClick(3)} />
          <label className="btn btn-primary" htmlFor="btnradio4">3 Min</label>

          <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" onClick={() => handleIntervalClick(5)} />
          <label className="btn btn-primary rounded-end" htmlFor="btnradio5">5 Min</label>
        </div>
        <div className="mt-2">
          <button className="btn btn-primary me-2" onClick={startInterval} disabled={isIntervalRunning}>Start</button>
          <button className="btn btn-danger" onClick={stopInterval} disabled={!isIntervalRunning}>Stop</button>
        </div>
      </div>
	  </div>
    </div>
    </div>
</div>
{niftyFilterDb && niftyFilterDb.length > 0 ? (
	<div className="page-content">
		<h6 className="mb-0 text-uppercase">DataTable Import</h6>
			<hr/>
			<div className="top">
      <table>
      <thead>
          <tr>
              <td colSpan="2" className="red" scope="col">TvItm:{ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].tvItmCE}</td>
              <td colSpan="1" className="red">LTPChg:{ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].totalCELTPChg}</td>
              <td colSpan="1" className="red">OIChg:{niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].ceOiChangepr}%</td>
              <td colSpan="2" className="red">(CE)R: {targetCEVolume ? targetCEVolume.strick_price : 0}</td>
              <td colSpan="1" className="white">
                {niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].nifty_time_ce ? niftyFilterDb[0].nifty_time_ce : displayTime}
              </td>
              <td colSpan="2" className="green">(PE)S: {targetPEVolume ? targetPEVolume.strick_price : 0}</td>
              <td colSpan="1" className="green">OIChg:{ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].peOiChangepr}%</td>
              <td colSpan="1" className="green">LTPChg:{ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].totalPELTPChg}</td>
              <td colSpan="2" className="green">TvItm:{ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].tvItmPE}</td>
          </tr>    
      </thead>

      <thead>
        <tr>
        <td className="red" scope="col">IV</td>
        <td className="red">OI Chg</td>
        <td className="red">OI</td>
        <td className="red">Volume</td>
        <td className="red">VWAP</td>
        <td className="red">LTP/Chg(TV)</td>
        <td className="white">StrikePrice/PCROI</td>
        <td className="green">LTP/Chg(TV)</td>
        <td className="green">VWAP</td>
        <td className="green">Volume</td>
        <td className="green">OI</td>
        <td className="green">OI Chg</td>
        <td className="green">IV</td>
        </tr>    
      </thead>
      <tbody>
          {renderRows()}
          <tr className="center">
              <td colSpan="5"></td>
              <td colSpan="5">
                  <div className="text" style={{ height: '28px', maxWidth: '400px', width: '100%' }}>
                      <h4 data-bs-toggle="modal" href="#exampleModalToggleNIFTY" role="button" style={{ margin: '0' }}>F:{niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].fut_price} | Spot:{spotPriceDb}</h4>
                  </div>
              </td>
              <td colSpan="5"></td>
          </tr>
         {renderLower()}
    </tbody>     
      </table>
      <div class="container mt-1" id="main">
        <div class="container text-center" id="conbg">
          <div class="row justify-content-center">
            <div class="col-md-6"> 
              <div class="table-responsive">
                <table class="table borderless">
                  <tbody>
                    <tr id="myhead">
                      <td>
                        <button type="button" className="btn btn-outline-info"  data-toggle="tooltip"  title="Time Value CE">TV CE : { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].cePercentageTV}%</button>
                      </td>
                      <td className="text-center" >
                        <p data-bs-toggle="modal" href="#exampleModalToggleDelta" role="button"> Delta CE:{ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].delta_ce} | Delta PE:{  niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].delta_pe}</p>
                        <p style={{ marginTop: "-15px" }} data-bs-toggle="modal" href="#exampleModalToggleTV" role="button">
                          <span tabIndex="0" data-toggle="tooltip" title={`LTP CE : ${ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].totalCeLTP}`}>
                            LTP CE: { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].ce_ltp_pr}%
                          </span>
                          {" | "}
                          <span tabIndex="0" data-toggle="tooltip" title={`LTP PE : ${ niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].totalPeLTP}`}>
                            LTP PE: { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].pe_ltp_pr}%
                          </span>
                        </p>
                      </td>
                      <td>
                        <button type="button" className="btn btn-outline-info" tabIndex="0" data-toggle="tooltip"  title="Time Value PE"> TV PE : { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].pePercentageTV}%</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="container mb-5" id="conbg">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="table-container">
                <div class="table-responsive"> 
                  <table class="table borderless">
                    <tbody id="mytbl">
                      <tr id="mytr">
                        <td scope="col" class="text-center" >CPR Volume: { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].crpVolume || 0}</td>
                        <td scope="col" class="text-center" data-bs-toggle="modal" href="#singleMulti" role="button">PCR OI: { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].pcr_oi || 0}</td>
                        <td scope="col" class="text-center" data-bs-toggle="modal" href="#singleMultioichange" role="button">PCR OI Change : { niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].pcr_oichange || 0}</td>
                      </tr>
                      <tr>
                        <td class="text-center">Total Volume CE: {formatNumber( (niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].totalCeVolume) || 0)}</td>
                        <td class="text-center">Total OI CE:{formatNumber( (niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].total_ce_oi) || 0)}</td>
                        <td class="text-center">Total OI Change CE: {formatNumber((niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].total_ce_oi_change) || 0)}</td>
                      </tr>
                      <tr>
                        <td class="text-center">Total Volume PE: {formatNumber( (niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].totalPeVolume) || 0)}</td>
                        <td class="text-center">Total OI PE: {formatNumber( (niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].total_pe_oi) || 0)}</td>
                        <td class="text-center">Total OI Change PE: {formatNumber(( niftyFilterDb.length > 0 && niftyFilterDb[0] && niftyFilterDb[0].total_ce_oi_change) || 0)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
			 
		</div>
		 ) : (
			<div className="text-center" role="alert">
			  Please select expiry date, date and time.
			</div>
		  )}
	    {/* single graph for OI */}
		<div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h6>OI Percentage Graph - {filteredData.length > 0 && ( filteredData[0].strick_price) }</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {filteredData.length > 0 && (
              <Line
                data={{
                  labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                  datasets: [
                    {
                      label: `${optionType} OI %`,
                      data: filteredData.map(item => optionType === 'CE' ? item.ce_oi : item.pe_oi),
                      backgroundColor:  "#0061a4" ,
                      borderColor:  "#0061a4" ,
                      pointRadius: 0
                    },
                    {
                      label: `${optionType} Volume %`,
                      data: filteredData.map(item => optionType === 'CE' ? item.ce_volume : item.pe_volume),
                      backgroundColor:  "#9a25ae",
                      borderColor: "#9a25ae" ,
                      pointRadius: 0
                    },
                    {
                      label: `${optionType} OI Change %`,
                      data: filteredData.map(item => optionType === 'CE' ? item.ce_oi_change : item.pe_oi_change),
                      backgroundColor:  "#006e1c" ,
                      borderColor:  "#006e1c",
                      pointRadius: 0
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  scales: {
                    y: {
                      position: 'right',
                      suggestedMin: formatNumber(niftyData[0].pe_oi,niftyData[0].ce_oi,niftyData[0].ce_volume,niftyData[0].pe_volume,niftyData[0].ce_oi_change,niftyData[0].pe_oi_change),
                      suggestedMax: formatNumber(niftyData[niftyData.length - 1].pe_oi,niftyData[niftyData.length - 1].ce_oi,niftyData[niftyData.length - 1].pe_volume,niftyData[niftyData.length - 1].pe_volume,niftyData[niftyData.length - 1].pe_oi_change,niftyData[niftyData.length - 1].ce_oi_change),
                      ticks: {
                        callback: function(value, index, values) {
                          return formatNumber(value); // Use the formatNumber function to format the y-axis values
                        }
                      }
                    },
                    x: {
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 5,
                      },
                      grid: {
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      mode: 'index',
                      intersect: false,
                      callbacks: {
                        label: function (context) {
                          let label = context.dataset.label || '';
                
                          if (label) {
                            label += ': ';
                          }
                
                          if (context.dataset.yAxisID === 'y' && context.parsed.y !== null) {
                            // Format the y-value in the tooltip using the formatNumber function
                            label += formatNumber(context.parsed.y);
                          } else {
                            label += formatNumber(context.parsed.y);
                          }
                
                          return label;
                        }
                      }
                    },             
                  zoom: {
                    pan: {
                      enabled: true,
                      mode: "x",
                      speed: 20,
                      threshold: 10,
                    },
                    zoom: {
                      limits: {
                        x: {min: niftyData[0].nifty_time_ce, max: niftyData[niftyData.length - 1].nifty_time_ce}
                      },
                      wheel: {
                        enabled: true,
                      },
                      pinch: {
                        enabled: true,
                      },
                      mode: "x",
                      sensitivity: 3,
                      speed: 0, // Set the speed to 0 to disable automatic zoom out
                    },
                  },
                  annotation: {
                    annotations: [
                      {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: niftyData[0].nifty_time_ce,
                        borderColor: 'red',
                        borderWidth: 2,
                        label: {
                          content: 'Start Time',
                          enabled: true,
                        },
                      },
                      {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x',
                        value: niftyData[niftyData.length - 1].nifty_time_ce,
                        borderColor: 'blue',
                        borderWidth: 2,
                        label: {
                          content: 'End Time',
                          enabled: true,
                        },
                      },
                    ],
                  },
                },
                interaction: {
                  mode: "nearest",
                  axis: "xy",
                },
                transitions: {
                  show: {
                    animations: {
                      x: {
                        from: 0,
                      },
                      y: {
                        from: 0,
                      },
                    },
                  },
                  hide: {
                    animations: {
                      x: {
                        to: 0,
                      },
                      y: {
                        to: 0,
                      },
                    },
                  },
                }
              }} 
            />
          )}
          </div>
        </div>
      </div>
    </div>

    {/* multi graph for OI Change  */}
    <div className="modal fade" id="exampleModalToggleMulti" aria-hidden="true" aria-labelledby="exampleModalToggleLabelMulti" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '500px' }}> 
        <div className="modal-content">
          <div className="modal-header">
            <h6>OI Change Charts - {filteredData.length > 0 && ( filteredData[0].strick_price) }</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="oi-change" role="tabpanel" aria-labelledby="oi-change-tab">
              {filteredData.length > 0 && (
                <Line
                    data={{
                        labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                        datasets: [
                            {
                                label: "PCR",
                                data: filteredData.map(item => item.pcr_oichange),
                                backgroundColor: "#064FF0",
                                borderColor: "#064FF0",
                                yAxisID: 'y1', 
                                pointRadius:0,
                            },
                            {
                              label: "CE OI",
                              data: filteredData.map(item => item.ce_oi),
                              backgroundColor: "#9bdee6",
                              borderColor: "#9bdee6",
                              yAxisID: 'y2', 
                              pointRadius:0
                          },
                            {
                                label: "PE OI",
                                data: filteredData.map(item => item.pe_oi),
                                backgroundColor: "#FF3030",
                                borderColor: "#FF3030",
                                yAxisID: 'y2', 
                                pointRadius:0
                            },
                           
                        ],
                    }}
                    options={{
                      scales: {
                        y1: {
                          position: 'left',
                          suggestedMin: Math.min(...filteredData.map(item => parseFloat(item.pcr_oichange).toFixed(2))),
                          suggestedMax: Math.max(...filteredData.map(item => parseFloat(item.pcr_oichange).toFixed(2))),
                          ticks: {
                              callback: function(value, index, values) {
                                  return parseFloat(value).toFixed(2);
                              }
                          },
                      },                                           
                        y2: { 
                          position: 'right',
                          suggestedMin: formatNumber(niftyData[0].pe_oi,niftyData[0].ce_oi),
                          suggestedMax: formatNumber(niftyData[niftyData.length - 1].pe_oi,niftyData[niftyData.length - 1].ce_oi),
                          ticks: {
                            callback: function(value, index, values) {
                              return formatNumber(value);
                            }
                          },
                        },
                      },          
                        responsive: true,
                        plugins: {
                            title: {
                                text: "Percentage Graph",
                            },
                            tooltip: {
                              mode: 'index',
                              intersect: false,
                              callbacks: {
                                label: function (context) {
                                  let label = context.dataset.label || '';
                            
                                  if (label) {
                                    label += ': ';
                                  }
                            
                                  if (context.dataset.yAxisID === 'y2' && context.parsed.y !== null) {
                                    // Format only if the dataset belongs to y2 axis
                                    label += formatNumber(context.parsed.y);
                                  } else {
                                    label += context.parsed.y;
                                  }
                            
                                  return label;
                                }
                              }
                            },                            
                            zoom: {
                                pan: {
                                    enabled: true,
                                    mode: "x",
                                    speed: 20,
                                    threshold: 10,
                                },
                                zoom: {
                                    wheel: {
                                        enabled: true,
                                    },
                                    pinch: {
                                        enabled: true,
                                    },
                                    mode: "x",
                                },
                            },
                        },
                        events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                        interaction: {
                            mode: 'nearest',
                            axis: 'xy',
                        },
                        transitions: {
                            show: {
                                animations: {
                                    x: {
                                        from: 0
                                    },
                                    y: {
                                        from: 0
                                    }
                                }
                            },
                            hide: {
                                animations: {
                                    x: {
                                        to: 0
                                    },
                                    y: {
                                        to: 0
                                    }
                                }
                            }
                        },
                    }}    
                />
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Model for volume  CE*/}
    <div className="modal fade" id="exampleModalToggleVolumeCE" aria-hidden="true" aria-labelledby="exampleModalToggleLabelVolumeCE" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '290px', height: '100px' }}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="negative-value" style={{ fontSize: "24px" }}>Possible Reversal for:<br /><span className="negative-value" style={{ fontSize: "24px" }}>{strickPrice !== null ? strickPrice : ''}</span></div>
            <div className="negative-value">Resistance: &nbsp;
              <span style={{ color: "black" }}>
                {spotPrice} 
                <i className='bx bx-copy-alt' style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(spotPrice).then(() => console.log('Spot price copied to clipboard:', spotPrice)).catch(err => console.error('Failed to copy spot price:', err))}></i>
              </span>
            </div>
          </div>
        </div>
      </div>  
    </div>


    {/* Model for volume  PE*/}
    <div className="modal fade" id="exampleModalToggleVolumePE" aria-hidden="true" aria-labelledby="exampleModalToggleLabelVolumePE" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '290px', height: '100px' }}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="positive-value" style={{ fontSize: "24px" }}>Possible Reversal for:<br /><span className="positive-value" style={{ fontSize: "24px" }}> {strickPrice !== null ? strickPrice : ''}</span></div>
                            <div className="positive-value">Support:{nextTsl2 !== undefined ? nextTsl2 : 'N/A'} &nbsp;<span style={{ color: "black" }}> <i className='bx bx-copy-alt' style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(nextTsl2).then(() => console.log('Spot price copied to clipboard:', spotPrice)).catch(err => console.error('Failed to copy spot price:', err))}></i></span></div>
                        </div>
                    </div>
                </div>
            </div>


    {/* Model for LTP Calculater*/}
    <div className="modal fade" id="exampleModalToggleLTPChg" aria-hidden="true" aria-labelledby="exampleModalToggleLabelLTPChg" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '390px', height: '100px' }}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="normal-value" style={{ fontSize: "24px", fontStyle: "italic" }}>LTP Calculator {strickPrice} {optionType}</div>
            <input type='text' id="box" placeholder='Target Spot Price' value={targetSpotPrice} onChange={handleChange}></input>
            <div className="normal-value" style={{ marginTop: "50px" }}>LTP : &nbsp;
              <span style={{ color: "black" }}>
                {ltp}
              </span>
            </div>
            <div className="normal-value" style={{ marginTop: "50px" }}>Buy Price: &nbsp;
              <span style={{ color: "black" }}>
                {typeof buyPrice === 'number' ? buyPrice.toFixed(2) : ''}
              </span>
            </div>
            <div className="negative-value">SL: &nbsp;<span style={{ color: "black" }}>{typeof sl === 'number' ? sl.toFixed(2) : ''}</span></div>
            <div className="positive-value">Target: &nbsp;<span style={{ color: "black" }}>{typeof target === 'number' ? target.toFixed(2) : ''}</span></div>

            <div className="negative-value" style={{ fontSize: "12px", marginTop: "50px" }}>This is a Rough Estimate. Calculation is based on delta.<br />Please use your own judgment and do not trade based on this alone.</div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="exampleModalToggleLTPChgPE" aria-hidden="true" aria-labelledby="exampleModalToggleLabelLTPChgPE" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '390px', height: '100px' }}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="normal-value" style={{ fontSize: "24px", fontStyle: "italic" }}>LTP Calculator {strickPrice} {optionType}</div>
            <input type='text' id="box" placeholder='Target Spot Price' value={targetSpotPrice} onChange={handleChange}></input>
            <div className="normal-value" style={{ marginTop: "50px" }}>LTP : &nbsp;
              <span style={{ color: "black" }}>
                {ltp}
              </span>
            </div>
            <div className="normal-value" style={{ marginTop: "50px" }}>Buy Price: &nbsp;
              <span style={{ color: "black" }}>
                {typeof buyPricePe === 'number' ? buyPricePe.toFixed(2) : ''}
              </span>
            </div>
            <div className="negative-value">SL: &nbsp;<span style={{ color: "black" }}>{typeof slPE === 'number' ? slPE.toFixed(2) : ''}</span></div>
            <div className="positive-value">Target: &nbsp;<span style={{ color: "black" }}>{typeof targetPE === 'number' ? targetPE.toFixed(2) : ''}</span></div>

            <div className="negative-value" style={{ fontSize: "12px", marginTop: "50px" }}>This is a Rough Estimate. Calculation is based on delta.<br />Please use your own judgment and do not trade based on this alone.</div>
          </div>
        </div>
      </div>
    </div>

    {/* single graph for Future buildup:NIFTY */}
    <div className="modal fade" id="exampleModalToggleNIFTY" aria-hidden="true" aria-labelledby="exampleModalToggleLabelNIFTY" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> 
        <div className="modal-content">
          <div className="modal-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h6 style={{textAlign:"center"}}>Future Buildup:NIFTY</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {filteredData.length > 0 && (
          <Line
                  data={{
                    labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                    datasets: [
                      {
                        label: "Future Price",
                        data: filteredData.map(item => item.fut_price),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        pointRadius:0
                      },
                      {
                        label: "Sport Price ",
                        data: filteredData.map(item => item.spot_price),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        pointRadius:0
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          callback: function(value, index, values) {
                              return value; // Format the tick value
                          }
                      }
                      },
                     
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />)}
          </div>
        </div>
      </div>
    </div>
    <div className="modal fade" id="exampleModalToggleVWAP" aria-hidden="true" aria-labelledby="exampleModalToggleVWAP" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> 
        <div className="modal-content">
          <div className="modal-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h6 style={{textAlign:"center"}}>Future Buildup:NIFTY</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {filteredData.length > 0 && (
          <Line
                  data={{
                    labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                    datasets: [
                      {
                        label: `${optionType} LTP %`,
                        data: filteredData.map(item => optionType === 'CE' ? item.ce_ltp : item.pe_ltp),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        pointRadius:0
                      },
                      {
                        label: `${optionType} VWAP %`,
                        data: filteredData.map(item => optionType === 'CE' ? item.ce_vwap : item.pe_vwap),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        pointRadius:0
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          callback: function(value, index, values) {
                              return value; // Format the tick value
                          }
                      }
                      },
                     
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />)}
          </div>
        </div>
      </div>
    </div>
    {/* single graph for combine price chart */}
    <div className="modal fade" id="exampleModalToggleIV" aria-hidden="true" aria-labelledby="exampleModalToggleLabelIV" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> 
        <div className="modal-content">
          <div className="modal-header">
            <h6>IV Price Chart - {filteredData.length > 0 && ( filteredData[0].strick_price) }</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {filteredData.length > 0 && (
          <Line
          data={{
            labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
            datasets: [
              {
                label: "Combine Price ",
                data: filteredData.map(item => item.ce_ltp + item.pe_ltp), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
                pointRadius:0,
              },
              {
                label: "CE Price",
                data: filteredData.map(item => item.ce_ltp),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
                pointRadius:0,
              },
              {
                label: "PE Price",
                data: filteredData.map(item => item.pe_ltp), // Corrected
                backgroundColor: "#006e1c",
                borderColor: "#006e1c",
                pointRadius:0,
              },
              
            ],
          }}
          options={{
            scales: {
              x: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 5,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                position: 'right', // Display y-axis on the right side
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  callback: function(value, index, values) {
                      return value; // Format the tick value
                  }
              }
              },
            },
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
                zoom: {
                pan: {
                  enabled: true, // Enable panning
                  mode: "x", // Allow panning in both x and y directions
                  speed: 20, // Panning speed
                  threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                  wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                  },
                  pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                  },
                  mode: "x", // Allow zooming in both x and y directions
                },
                },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />)}
          </div>
        </div>
      </div>
    </div>

    {/* single graph for Time value */}
    <div className="modal fade" id="exampleModalToggleTV" aria-hidden="true" aria-labelledby="exampleModalToggleLabelTV" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h6>LTP Value</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {filteredData.length > 0 && (
          <Line
          data={{
            labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
            datasets: [
              {
                label: "CE LTP",
                data: filteredData.map(item => item.ce_ltp_pr), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
                pointRadius:0,
              },
              {
                label: "PE LTP",
                data: filteredData.map(item => item.pe_ltp_pr),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
                pointRadius:0,
              }
            ],
          }}
          options={{
            
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
                zoom: {
                pan: {
                  enabled: true, // Enable panning
                  mode: "x", // Allow panning in both x and y directions
                  speed: 20, // Panning speed
                  threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                  wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                  },
                  pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                  },
                  mode: "x", // Allow zooming in both x and y directions
                },
                },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />)}
          </div>
        </div>
      </div>
    </div>

    {/* single graph for Delta Near sport price */}
    <div className="modal fade" id="exampleModalToggleDelta" aria-hidden="true" aria-labelledby="exampleModalToggleLabelDelta" tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> 
      <div className="modal-content">
        <div className="modal-header">
          <h6>Delta Near Spot Price</h6>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        {filteredData.length > 0 && (
        <Line
          data={{
            labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
            datasets: [
              {
                label: "Delta CE",
                data: filteredData.map(item => item.delta_ce), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
                pointRadius:0,
              },
              {
                label: "Delta PE",
                data: filteredData.map(item => item.delta_pe),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
                pointRadius:0,
              },
              {
                label: "Delta (CE-PE)",
                data: filteredData.map(item => item.delta_ce - item.delta_pe), // Corrected
                backgroundColor: "#006e1c",
                borderColor: "#006e1c",
                pointRadius:0,
              },
              
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
              zoom: {
                pan: {
                enabled: true, // Enable panning
                mode: "x", // Allow panning in both x and y directions
                speed: 20, // Panning speed
                threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                },
                pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                },
                mode: "x", // Allow zooming in both x and y directions
                },
              },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />)}
        </div>
      </div>
    </div>
    </div>

    {/* multi graph for pcr OI   */}
    <div className="modal fade" id="singleMulti" aria-hidden="true" aria-labelledby="singleMulti" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '500px' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h6>PCR and PCR Charts</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">

                    <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane fade ${activeTab === 'oi-change' ? 'show active' : ''}`} id="oi-change" role="tabpanel" aria-labelledby="oi-change-tab">
                        {filteredData.length > 0 && (
                          <Line
                              data={{
                                  labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                                  datasets: [
                                      {
                                          label: "PCR",
                                          data: filteredData.map(item => item.pcr_oi),
                                          backgroundColor: "#064FF0",
                                          borderColor: "#064FF0",
                                          yAxisID: 'y1', 
                                          pointRadius:0,
                                      },
                                      {
                                        label: "CE OI",
                                        data: filteredData.map(item => item.total_ce_oi),
                                        backgroundColor: "#9bdee6",
                                        borderColor: "#9bdee6",
                                        yAxisID: 'y2', 
                                        pointRadius:0
                                    },
                                      {
                                          label: "PE OI",
                                          data: filteredData.map(item => item.total_pe_oi),
                                          backgroundColor: "#FF3030",
                                          borderColor: "#FF3030",
                                          yAxisID: 'y2', 
                                          pointRadius:0
                                      },
                                    
                                  ],
                              }}
                              options={{
                                scales: {
                                  y1: {
                                    position: 'left',
                                    suggestedMin: Math.min(...filteredData.map(item => parseFloat(item.pcr_oi).toFixed(2))),
                                    suggestedMax: Math.max(...filteredData.map(item => parseFloat(item.pcr_oi).toFixed(2))),
                                    ticks: {
                                        callback: function(value, index, values) {
                                            return parseFloat(value).toFixed(2);
                                        }
                                    },
                                },  
                                  y2: { 
                                    position: 'right',
                                    suggestedMin: formatNumber(niftyData[0].total_pe_oi,niftyData[0].total_ce_oi),
                                    suggestedMax: formatNumber(niftyData[niftyData.length - 1].total_pe_oi,niftyData[niftyData.length - 1].total_ce_oi),
                                    ticks: {
                                      callback: function(value, index, values) {
                                        return formatNumber(value);
                                      }
                                    },
                                  },
                                },          
                                  responsive: true,
                                  plugins: {
                                      title: {
                                          text: "Percentage Graph",
                                      },
                                      tooltip: {
                                        mode: 'index',
                                        intersect: false,
                                        callbacks: {
                                          label: function (context) {
                                            let label = context.dataset.label || '';
                                      
                                            if (label) {
                                              label += ': ';
                                            }
                                      
                                            if (context.dataset.yAxisID === 'y2' && context.parsed.y !== null) {
                                              // Format only if the dataset belongs to y2 axis
                                              label += formatNumber(context.parsed.y);
                                            } else {
                                              label += context.parsed.y;
                                            }
                                      
                                            return label;
                                          }
                                        }
                                      },                            
                                      zoom: {
                                          pan: {
                                              enabled: true,
                                              mode: "x",
                                              speed: 20,
                                              threshold: 10,
                                          },
                                          zoom: {
                                              wheel: {
                                                  enabled: true,
                                              },
                                              pinch: {
                                                  enabled: true,
                                              },
                                              mode: "x",
                                          },
                                      },
                                  },
                                  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                                  interaction: {
                                      mode: 'nearest',
                                      axis: 'xy',
                                  },
                                  transitions: {
                                      show: {
                                          animations: {
                                              x: {
                                                  from: 0
                                              },
                                              y: {
                                                  from: 0
                                              }
                                          }
                                      },
                                      hide: {
                                          animations: {
                                              x: {
                                                  to: 0
                                              },
                                              y: {
                                                  to: 0
                                              }
                                          }
                                      }
                                  },
                              }}    
                          />
                        )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* multi graph for OI Change  */}
    <div className="modal fade" id="singleMultioichange" aria-hidden="true" aria-labelledby="singleMultioichange" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '500px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>PCR and CPR Charts</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${activeTab === 'oi-change' ? 'show active' : ''}`} id="oi-change" role="tabpanel" aria-labelledby="oi-change-tab">
                            {filteredData.length > 0 && (
                              <Line
                                  data={{
                                      labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                                      datasets: [
                                          {
                                              label: "PCR",
                                              data: filteredData.map(item => item.pcr_oichange),
                                              backgroundColor: "#064FF0",
                                              borderColor: "#064FF0",
                                              yAxisID: 'y1', 
                                              pointRadius:0,
                                          },
                                          {
                                            label: "CE OI",
                                            data: filteredData.map(item => item.total_ce_oi_change),
                                            backgroundColor: "#9bdee6",
                                            borderColor: "#9bdee6",
                                            yAxisID: 'y2', 
                                            pointRadius:0
                                        },
                                          {
                                              label: "PE OI",
                                              data: filteredData.map(item => item.total_pe_oi_change),
                                              backgroundColor: "#FF3030",
                                              borderColor: "#FF3030",
                                              yAxisID: 'y2', 
                                              pointRadius:0
                                          },
                                        
                                      ],
                                  }}
                                  options={{
                                    scales: {
                                      y1: {
                                        position: 'left',
                                        suggestedMin: Math.min(...filteredData.map(item => parseFloat(item.pcr_oichange).toFixed(2))),
                                        suggestedMax: Math.max(...filteredData.map(item => parseFloat(item.pcr_oichange).toFixed(2))),
                                        ticks: {
                                            callback: function(value, index, values) {
                                                return parseFloat(value).toFixed(2);
                                            }
                                        },
                                    },  
                                      y2: { 
                                        position: 'right',
                                        suggestedMin: formatNumber(niftyData[0].total_pe_oi_change,niftyData[0].total_ce_oi_change),
                                        suggestedMax: formatNumber(niftyData[niftyData.length - 1].total_pe_oi_change,niftyData[niftyData.length - 1].total_ce_oi_change),
                                        ticks: {
                                          callback: function(value, index, values) {
                                            return formatNumber(value);
                                          }
                                        },
                                      },
                                    },          
                                      responsive: true,
                                      plugins: {
                                          title: {
                                              text: "Percentage Graph",
                                          },
                                          tooltip: {
                                            mode: 'index',
                                            intersect: false,
                                            callbacks: {
                                              label: function (context) {
                                                let label = context.dataset.label || '';
                                          
                                                if (label) {
                                                  label += ': ';
                                                }
                                          
                                                if (context.dataset.yAxisID === 'y2' && context.parsed.y !== null) {
                                                  // Format only if the dataset belongs to y2 axis
                                                  label += formatNumber(context.parsed.y);
                                                } else {
                                                  label += context.parsed.y;
                                                }
                                          
                                                return label;
                                              }
                                            }
                                          },                            
                                          zoom: {
                                              pan: {
                                                  enabled: true,
                                                  mode: "x",
                                                  speed: 20,
                                                  threshold: 10,
                                              },
                                              zoom: {
                                                  wheel: {
                                                      enabled: true,
                                                  },
                                                  pinch: {
                                                      enabled: true,
                                                  },
                                                  mode: "x",
                                              },
                                          },
                                      },
                                      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                                      interaction: {
                                          mode: 'nearest',
                                          axis: 'xy',
                                      },
                                      transitions: {
                                          show: {
                                              animations: {
                                                  x: {
                                                      from: 0
                                                  },
                                                  y: {
                                                      from: 0
                                                  }
                                              }
                                          },
                                          hide: {
                                              animations: {
                                                  x: {
                                                      to: 0
                                                  },
                                                  y: {
                                                      to: 0
                                                  }
                                              }
                                          }
                                      },
                                  }}    
                              />
                            )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    </div>
     {/* single graph for OI */}
     <div className="modal fade" id="exampleModalToggleLTPChg" aria-hidden="true" aria-labelledby="exampleModalToggleLTPChg" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}>
        <div className="modal-content">
          <div className="modal-header">
            <h6>LTP Change Percentage Graph - {filteredData.length > 0 && ( filteredData[0].strick_price) }</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          {filteredData.length > 0 && (
                              <Line
                                  data={{
                                      labels: filteredData.map(item => new Date(optionType === 'CE' ? item.nifty_time_ce : item.nifty_time_pe).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })),
                                      datasets: [
                                          {
                                              label: "CE LTP Change %",
                                              data: filteredData.map(item => item.pcr_oichange),
                                              backgroundColor: "#064FF0",
                                              borderColor: "#064FF0",
                                              yAxisID: 'y1', 
                                              pointRadius:0,
                                          },
                                          {
                                            label: "PE LTP Change %",
                                            data: filteredData.map(item => item.total_ce_oi_change),
                                            backgroundColor: "#9bdee6",
                                            borderColor: "#9bdee6",
                                            yAxisID: 'y2', 
                                            pointRadius:0
                                        },
                                          {
                                              label: "CE-PE LTP Change",
                                              data: filteredData.map(item => item.total_pe_oi_change),
                                              backgroundColor: "#FF3030",
                                              borderColor: "#FF3030",
                                              yAxisID: 'y2', 
                                              pointRadius:0
                                          },
                                        
                                      ],
                                  }}
                                  options={{
                                    scales: {
                                      y1: {
                                        position: 'left',
                                        suggestedMin: Math.min(...filteredData.map(item => parseFloat(item.pcr_oichange).toFixed(2))),
                                        suggestedMax: Math.max(...filteredData.map(item => parseFloat(item.pcr_oichange).toFixed(2))),
                                        ticks: {
                                            callback: function(value, index, values) {
                                                return parseFloat(value).toFixed(2);
                                            }
                                        },
                                    },  
                                      y2: { 
                                        position: 'right',
                                        suggestedMin: formatNumber(niftyData[0].total_pe_oi_change,niftyData[0].total_ce_oi_change),
                                        suggestedMax: formatNumber(niftyData[niftyData.length - 1].total_pe_oi_change,niftyData[niftyData.length - 1].total_ce_oi_change),
                                        ticks: {
                                          callback: function(value, index, values) {
                                            return formatNumber(value);
                                          }
                                        },
                                      },
                                    },          
                                      responsive: true,
                                      plugins: {
                                          title: {
                                              text: "Percentage Graph",
                                          },
                                          tooltip: {
                                            mode: 'index',
                                            intersect: false,
                                            callbacks: {
                                              label: function (context) {
                                                let label = context.dataset.label || '';
                                          
                                                if (label) {
                                                  label += ': ';
                                                }
                                          
                                                if (context.dataset.yAxisID === 'y2' && context.parsed.y !== null) {
                                                  // Format only if the dataset belongs to y2 axis
                                                  label += formatNumber(context.parsed.y);
                                                } else {
                                                  label += context.parsed.y;
                                                }
                                          
                                                return label;
                                              }
                                            }
                                          },                            
                                          zoom: {
                                              pan: {
                                                  enabled: true,
                                                  mode: "x",
                                                  speed: 20,
                                                  threshold: 10,
                                              },
                                              zoom: {
                                                  wheel: {
                                                      enabled: true,
                                                  },
                                                  pinch: {
                                                      enabled: true,
                                                  },
                                                  mode: "x",
                                              },
                                          },
                                      },
                                      events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                                      interaction: {
                                          mode: 'nearest',
                                          axis: 'xy',
                                      },
                                      transitions: {
                                          show: {
                                              animations: {
                                                  x: {
                                                      from: 0
                                                  },
                                                  y: {
                                                      from: 0
                                                  }
                                              }
                                          },
                                          hide: {
                                              animations: {
                                                  x: {
                                                      to: 0
                                                  },
                                                  y: {
                                                      to: 0
                                                  }
                                              }
                                          }
                                      },
                                  }}    
                              />
                            )}
          </div>
        </div>
      </div>
    </div>
		</div>
        {/* <!--start overlay--> */}
		 <div className="overlay toggle-icon"></div>
		{/* <!--end overlay--> */}
		{/* <!--Start Back To Top Button--> */}
		  <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
		{/* <!--End Back To Top Button--> */}
		{/* <!--end page wrapper --> */}
     </div> 
    </div>
  );
};

export default BackTester;
