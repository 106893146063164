import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export const UserManagement = () => {
  const [value, setValues] = useState([]);

  const getUserData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/admin`);
      if (res.status === 200) {
        const filteredData = res.data.data.filter(user => user.user_role === "user");
        setValues(filteredData);
        // setValues(res.data.data);
      } else {
        setValues([]);
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        type: "error",
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this User",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5000C0",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        const res = await axios.delete(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/delete/` + id
        );
        if (res.status === 200) {
          getUserData();
          Swal.fire({
            title: "Deleted!",
            text: "This User has been deleted.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            title: "Deleted failed!",
            text: "User deleted failed.",
            icon: "error",
          });
        }
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const toggleUserStatus = async (id, currentStatus) => {
    try {
      // Send an API request to update the status
      const updatedStatus = currentStatus === 1 ? 0 : 1;
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/update/${id}`,
        { status: updatedStatus }
      );
      if (res.status === 200) {
        // Update the state with the updated status
        const updatedUsers = value.map(user => {
          if (user.id === id) {
            return {
              ...user,
              status: updatedStatus
            };
          }
          return user;
        });
        setValues(updatedUsers);
      } else {
        Swal.fire({
          title: "Update failed!",
          text: "User status update failed.",
          icon: "error",
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <>
    <div className="wrapper">
    <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Tables</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          
            <h6 className="mb-0 text-uppercase">User Management</h6>
           
          <hr />
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table
                  id="example"
                  className="table table-striped table-bordered"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th class="text-center align-middle">Index</th>
                      <th class="text-center align-middle">User Name</th>
                      <th class="text-center align-middle">Email</th>
                      <th class="text-center align-middle">Contact</th>
                      <th class="text-center align-middle"> User Type</th>
                      <th class="text-center align-middle">Status</th>
                      <th class="text-center align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.length === 0 ? (
                      <tr>
                        <td colSpan="12">No data available</td>
                      </tr>
                    ) : (
                      value.map((dataa, index) => {
                        return (
                          <tr key={index}>
                            <td class="text-center align-middle">{index + 1}</td>
                            <td class="text-center align-middle">{dataa.name}</td>
                            <td class="text-center align-middle">{dataa.email}</td>
                            <td class="text-center align-middle">{dataa.contact}</td>
                            <td class="text-center align-middle">{dataa.user_role}</td>
                            <td class="text-center align-middle">
                              {dataa.status === 1 ? (
                                <button
                                  className="btn btn-success"
                                  onClick={() =>
                                    toggleUserStatus(dataa.id, dataa.status)
                                  }
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    toggleUserStatus(dataa.id, dataa.status)
                                  }
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                            <td class="text-center align-middle">
                              <div className="table-actions d-flex justify-content-center">
                                <Link
                                  className="delete-table me-2"
                                  to={`/edituser/${dataa.id}`}
                                >
                                  <img
                                    src="assets/images/icons/edit.svg"
                                    alt="svg"
                                  />
                                </Link>
                                <div
                                  className="delete-table"
                                  onClick={() => handleDelete(dataa.id)}
                                >
                                  <img
                                    src="assets/images/icons/delete.svg"
                                    alt="svg"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
