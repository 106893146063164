import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import "../style.css"
import "../table.css"
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // Import Chart directly from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import axios from "axios";
import iv from 'implied-volatility';
import greeks from 'greeks'
Chart.register(zoomPlugin);

export const NiftyFinSrvice = () => {
  const [activeTab, setActiveTab] = useState('oi-change');
	const [nifty50, setNifty50] = useState([]);
	const [niftyFilter, setNiftyFilter] = useState([]);
  const [spotPrice,setSpotPrice] = useState([])
	const [upper,setUpper] = useState(null)
	const [lower,setLower] = useState(null)
	const revenueData = [
		{ "label": "10:30:02", "OI": 10, "OI Change": 20 ,"Volume" :30,"LTP CE":20,"LTP PE":12},
		{ "label": "10:31:02", "OI": 50, "OI Change": 70 ,"Volume" :40,"LTP CE":40,"LTP PE":15},
		{ "label": "10:32:02", "OI": 30, "OI Change": 80 ,"Volume" :50,"LTP CE":50,"LTP PE":16},
		{ "label": "10:33:02", "OI": 10, "OI Change": 29 ,"Volume" :60,"LTP CE":30,"LTP PE":90},
		{ "label": "10:34:02", "OI": 79, "OI Change": 65 ,"Volume" :70,"LTP CE":56,"LTP PE":88},
		{ "label": "10:35:02", "OI": 86, "OI Change": 22 ,"Volume" :80,"LTP CE":33,"LTP PE":77},
		{ "label": "10:36:02", "OI": 65, "OI Change": 56 ,"Volume" :90,"LTP CE":11,"LTP PE":66},
		{ "label": "10:37:02", "OI": 96, "OI Change": 45 ,"Volume" :10,"LTP CE":88,"LTP PE":55},
		{ "label": "10:38:02", "OI": 30, "OI Change": 70 ,"Volume" :20,"LTP CE":97,"LTP PE":44},
		{ "label": "10:39:02", "OI": 40, "OI Change": 30 ,"Volume" :30,"LTP CE":33,"LTP PE":33},
		{ "label": "10:40:02", "OI": 10, "OI Change": 90 ,"Volume" :40,"LTP CE":44,"LTP PE":22},
		{ "label": "10:45:02", "OI": 30, "OI Change": 20 ,"Volume" :60,"LTP CE":26,"LTP PE":11}
	  ];
    const handleTabChange = (tabId) => {
      setActiveTab(tabId);
  };
  const navigate = useNavigate();
  function getAccessToken() {
    const userDataJSON = sessionStorage.getItem('userData');
  
    if (!userDataJSON) {
      navigate("/login")
      return null; 
    } else {
      const userData = JSON.parse(userDataJSON);
    
      if (userData && userData.result && userData.result.length > 0 && userData.result[0].accessToken) {
        const accessToken = userData.result[0].accessToken;
        return accessToken; // Return the access token
      } else {
        console.error('Access token is missing or invalid');
        return null; 
      }
    }
  }
  // Call the function to get the access token
  const accessToken = getAccessToken();
  useEffect(() => {
    const fetchNifty50Data = async () => {
      try {
          if (!accessToken) {
              console.error('Access token not found ');
              return;
          }
  
          const backendUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/quote/niftyfinservice`;
          const response = await axios.get(backendUrl, {
              params: {
                  api_key: process.env.REACT_APP_API_KEY,
                  accessToken: accessToken
              }
          });
  
          const lastPrice = response.data.data?.["NSE:NIFTY FIN SERVICE"]?.last_price;
          setSpotPrice(response.data.data?.["NSE:NIFTY FIN SERVICE"]?.last_price);
  
          if (!lastPrice) {
              console.error('Last price not found in response');
              return;
          }
          setNifty50(response.data)
          const remainder = lastPrice % 50;
          const lowerRoundedValue = lastPrice - remainder;
          const upperRoundedValue = lowerRoundedValue + 50;
          const adjustedLower = Math.floor(lowerRoundedValue / 50) * 50;
          const adjustedUpper = Math.ceil(upperRoundedValue / 50) * 50;
  
          setUpper(adjustedUpper);
          setLower(adjustedLower);
    
          console.log("nifty",response.data);
      } catch (error) {
          console.log(error);
      }
  };
  const fetchNiftyFilterData = async () => {
      try {
          const backendUrlFilter = `${process.env.REACT_APP_BACKEND_BASE_URL}/getinstrumentfilterfinnifty`;
          const params = {
              api_key: process.env.REACT_APP_API_KEY,
              accessToken: accessToken
          };
              const responseFilter = await axios.post(backendUrlFilter, {
                  accessToken: accessToken,
                  exchange: "NFO",
                  name: "FINNIFTY"
              }, {
                  params,
                  headers: {
                      'Content-Type': 'application/json'
                  }
              });
             
              setNiftyFilter(responseFilter.data);
      } catch (error) {
          console.error("Error fetching Filter Nifty 50:", error);
      }
  };

    // Call the data fetching functions immediately when the component mounts
    fetchNifty50Data();
    fetchNiftyFilterData();

    // Set up intervals to fetch data every 5 seconds and every 30 seconds
    const fetchDataInterval = setInterval(fetchNifty50Data, 5000);
    const fetchFilterDataInterval = setInterval(fetchNiftyFilterData, 30000);

    // Clean up intervals on component unmount
    return () => {
      clearInterval(fetchDataInterval);
      clearInterval(fetchFilterDataInterval);
    };
  }, []);
 
  

const postNiftyData = async (strick_price, ceData, peData, formattedCeIV, formattedPeIV, ceDelta, peDelta, ceGamma, peGamma) => {
    try {
        const postData = {
            spot_price: nifty50.data?.["NSE:NIFTY FIN SERVICE"]?.last_price,
            strick_price,
            ce_oi: ceData.oi,
            ce_volume: ceData.volume,
            pe_oi: peData.oi,
            pe_volume: peData?.volume,
            ce_ltp: ceData.last_price,
            pe_ltp: peData.last_price,
            ce_iv: formattedCeIV,
            pe_iv: formattedPeIV,
            ce_delta: ceDelta,
            ce_gamma: ceGamma,
            pe_delta: peDelta,
            pe_gamma: peGamma,
            nifty_time_ce: ceData.last_trade_time,
            nifty_time_pe: peData.last_trade_time
        };

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/addniftyfinservice`, postData);

        if (response.status === 200) {
            console.log('Data posted successfully:', response.data);
        } else if (response.status === 400) {
            console.log('Data not posted - received 400 status code');
        } else {
            console.log('Unexpected status code:', response.status);
        }
    } catch (error) {
        console.error('Error posting Nifty data:', error);
    }
};


function formatNumber(num) {
	const suffixes = [' ', 'K', 'M', 'B'];
  
	const suffixIndex = Math.floor((String(num).length - 1) / 3);
  
	let scaledNum = num / Math.pow(1000, suffixIndex);
  
	let formattedNum = scaledNum.toFixed(2);
  
	formattedNum += suffixes[suffixIndex];
  
	return formattedNum ;
  }
  const [strickPrice, setStrickPrice] = useState(null);
  const renderRows = () => {
    const rows = [];
    let maxCE_OI = 0;
    let maxCE_volume = 0;
    let maxPE_OI = 0;
    let maxPE_volume = 0;
 // Check if niftyFilter is defined and has data
 if (!niftyFilter || !niftyFilter.CE || !niftyFilter.PE) {
  // Handle the case where niftyFilter is not available
  return <tr><td colSpan="12">Loading...</td></tr>; // Display a loading message
}
    // Find the maximum value of CE oi and volume within the upper range
    for (let i = -11; i <= 0; i++) {
        const filterItem = niftyFilter.CE.filter(item=>item.strickPrice === upper - i * 50);
        const filterItempe = niftyFilter.PE.filter(item=>item.strickPrice === upper - i * 50);
        const ceData = filterItem;
        const peData = filterItempe;
        if (ceData.length > 0 && ceData[0].oi > maxCE_OI) {
            maxCE_OI = ceData[0].oi;
        }
        if (ceData.length > 0 && ceData[0].volume > maxCE_volume) {
            maxCE_volume = ceData[0].volume;
        }
        if (peData.length > 0 && peData[0].oi > maxPE_OI) {
            maxPE_OI = peData[0].oi;
        }
        if (peData.length > 0 && peData[0].volume > maxPE_volume) {
            maxPE_volume = peData[0].volume;
        }
    }
  // Find the maximum value of CE oi and volume within the lower range
  for (let i = 0; i <= 11; i++) {
    const filterItem = niftyFilter.CE.filter(item=>item.strickPrice === lower - i * 50);
    const filterItempe = niftyFilter.PE.filter(item=>item.strickPrice === lower - i * 50);
    const ceData = filterItem;
    const peData = filterItempe;
      if (ceData.length > 0 && ceData[0].oi > maxCE_OI) {
        maxCE_OI = ceData[0].oi;
      }
      if (ceData.length > 0 && ceData[0].volume > maxCE_volume) {
          maxCE_volume = ceData[0].volume;
      }
      if (peData.length > 0 && peData[0].oi > maxPE_OI) {
          maxPE_OI = peData[0].oi;
      }
      if (peData.length > 0 && peData[0].volume > maxPE_volume) {
          maxPE_volume = peData[0].volume;
      }
    }
    // Render rows for the upper range
    for (let i = -11; i <= 0; i++) {
      const filterItem = niftyFilter.CE.filter(item => item.strickPrice === upper - i * 50);
      const filterItempe = niftyFilter.PE.filter(item => item.strickPrice === upper - i * 50);
      const ceData = filterItem;
      const peData = filterItempe;
  
      let cePercent;
      let ceVolumePercent
      let pePercent
      let peVolumePercent
        // Calculate percentage based on the maximum value
        if (ceData.length > 0) {
          cePercent = maxCE_OI !== 0 ? (ceData[0].oi / maxCE_OI) * 100 : 0;
          ceVolumePercent = maxCE_volume !== 0 ? (ceData[0].volume / maxCE_volume) * 100 : 0;
      }
  
      if (peData.length > 0) {
          pePercent = maxPE_OI !== 0 ? (peData[0].oi / maxPE_OI) * 100 : 0;
          peVolumePercent = maxPE_volume !== 0 ? (peData[0].volume / maxPE_volume) * 100 : 0;
      }
  
      let cePercentStyle = {};
      let pePercentStyle = {};
      let cePercentStyleVolume = {};
      let pePercentStyleVolume = {};
  
      if (cePercent === 100) {
        cePercentStyle = { backgroundColor: "#b22c01",color: "#fff",textAlign: "center" };
      } else if (cePercent >= 95 && cePercent < 100) {
          cePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center"  };
      } else if (cePercent >= 75 && cePercent < 95) {
          cePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
      } else {
          cePercentStyle = { color: 'inherit' }; // Default color
      }

    // Set styles based on PE percentage
    if (pePercent === 100) {
        pePercentStyle = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
    } else if (pePercent >= 95 && pePercent < 100) {
        pePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
    } else if (pePercent >= 75 && pePercent < 95) {
        pePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center"};
    } else {
        pePercentStyle = { color: 'inherit' }; // Default color
    }

    // Set styles based on CE volume percentage
    if (ceVolumePercent === 100) {
        cePercentStyleVolume = {backgroundColor: "#b22c01",color: "#fff",textAlign: "center"  };
    } else if (ceVolumePercent >= 95 && ceVolumePercent < 100) {
        cePercentStyleVolume = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
    } else if (ceVolumePercent >= 75 && ceVolumePercent < 95) {
        cePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
    } else {
        cePercentStyleVolume = { color: 'inherit' }; // Default color
    }

    // Set styles based on PE volume percentage
    if (peVolumePercent === 100) {
        pePercentStyleVolume = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
    } else if (peVolumePercent >= 95 && peVolumePercent < 100) {
        pePercentStyleVolume = { backgroundColor: "#F6BE00",color: "#000",textAlign: "center"};
    } else if (peVolumePercent >= 75 && peVolumePercent < 95) {
        pePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
    } else {
        pePercentStyleVolume = { color: 'inherit' }; // Default color
    }

      rows.push(
          <tr key={i}>
              <td className="text-right-td">{ceData.length > 0 ? ceData[0].iv || 0 : 0}<br/><span>{ceData.length > 0 ? ceData[0].delta || 0 : 0}/{ceData.length > 0 ? ceData[0].gamma || 0 : 0}</span></td>
              <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button">19.73%<br/><span>25k</span></td>
              <td className="text-right-td" style={cePercentStyle} data-bs-toggle="modal" href="#exampleModalToggle" role="button">{cePercent !== undefined ? cePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(ceData[0] && ceData[0].oi !== undefined ? ceData[0].oi : 0) || 0}</span></td>
              <td className="text-right-td " style={cePercentStyleVolume} data-bs-toggle="modal" href="#exampleModalToggleVolumeCE" role="button" onClick={() => setStrickPrice(upper - i * 50)}>{ceVolumePercent !== undefined ? ceVolumePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(ceData[0] && ceData[0].volume !== undefined ? ceData[0].volume : 0) || 0}</span></td>
              <td className="text-right-td">{ceData[0] && ceData[0].vwap !== undefined ? ceData[0].vwap : 0}</td>
              <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChg" role="button">{ceData[0] && ceData[0].last_price !== undefined ? ceData[0].last_price : 0}<br/><span><span>-4.50</span>(8)</span></td>
              <td className="text-center-td">{upper - i * 50}<br/><span>0.11</span></td>
              <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChg" role="button">{peData[0] && peData[0].last_price !== undefined ? peData[0].last_price : 0}<br/><span><span>-4.50</span>(8)</span></td>
              <td className="text-left-td" >{peData[0] && peData[0].vwap !== undefined ? peData[0].vwap : 0}</td>
              <td className="text-left-td" style={pePercentStyleVolume} data-bs-toggle="modal" href="#exampleModalToggleVolumePE" role="button" onClick={() => setStrickPrice(upper - i * 50)}>{peVolumePercent !== undefined ? peVolumePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(peData[0] && peData[0].volume !== undefined ? peData[0].volume : 0) || 0}</span></td>
              <td className="text-left-td" style={pePercentStyle} data-bs-toggle="modal" href="#exampleModalToggle" role="button">{pePercent !== undefined ? pePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(peData[0] && peData[0].oi !== undefined ? peData[0].oi : 0) || 0}</span></td>
              <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button">0%<br/><span >-8.05K</span></td>
              <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleIV" role="button">{peData.length > 0 ? peData[0].iv || 0 : 0}<br/><span>{peData.length > 0 ? peData[0].delta || 0 : 0}/{peData.length > 0 ? peData[0].gamma || 0 : 0}</span></td>
          </tr>
      );
  }
    return rows;
};

const renderLower = () => {
  const rows = [];
  let maxCE_OI = 0;
  let maxCE_volume = 0;
  let maxPE_OI = 0;
  let maxPE_volume = 0;

// Check if niftyFilter is defined and has data
if (!niftyFilter || !niftyFilter.CE || !niftyFilter.PE) {
  // Handle the case where niftyFilter is not available
  return <tr><td colSpan="12">Loading...</td></tr>; // Display a loading message
}
   // Find the maximum value of CE oi and volume within the upper range
   for (let i = -11; i <= 0; i++) {
    const filterItem = niftyFilter.CE.filter(item=>item.strickPrice === upper - i * 50);
        const filterItempe = niftyFilter.PE.filter(item=>item.strickPrice === upper - i * 50);
        const ceData = filterItem;
        const peData = filterItempe;
        if (ceData.length > 0 && ceData[0].oi > maxCE_OI) {
          maxCE_OI = ceData[0].oi;
        }
        if (ceData.length > 0 && ceData[0].volume > maxCE_volume) {
            maxCE_volume = ceData[0].volume;
        }
        if (peData.length > 0 && peData[0].oi > maxPE_OI) {
            maxPE_OI = peData[0].oi;
        }
        if (peData.length > 0 && peData[0].volume > maxPE_volume) {
            maxPE_volume = peData[0].volume;
        }
}

  // Find the maximum value of CE oi and volume within the lower range
  for (let i = 0; i <= 11; i++) {
    const filterItem = niftyFilter.CE.filter(item=>item.strickPrice === lower - i * 50);
    const filterItempe = niftyFilter.PE.filter(item=>item.strickPrice === lower - i * 50);
    const ceData = filterItem;
    const peData = filterItempe;
    if (ceData.length > 0 && ceData[0].oi > maxCE_OI) {
      maxCE_OI = ceData[0].oi;
    }
    if (ceData.length > 0 && ceData[0].volume > maxCE_volume) {
        maxCE_volume = ceData[0].volume;
    }
    if (peData.length > 0 && peData[0].oi > maxPE_OI) {
        maxPE_OI = peData[0].oi;
    }
    if (peData.length > 0 && peData[0].volume > maxPE_volume) {
        maxPE_volume = peData[0].volume;
    }
}

  // Render rows for the lower range
  for (let i = 0; i <= 11; i++) {
    const filterItem = niftyFilter.CE.filter(item=>item.strickPrice === lower - i * 50);
    const filterItempe = niftyFilter.PE.filter(item=>item.strickPrice === lower - i * 50);
    const ceData = filterItem;
    const peData = filterItempe;
    let cePercent;
    let ceVolumePercent
    let pePercent
    let peVolumePercent
      // Calculate percentage based on the maximum value
      if (ceData.length > 0) {
        cePercent = maxCE_OI !== 0 ? (ceData[0].oi / maxCE_OI) * 100 : 0;
        ceVolumePercent = maxCE_volume !== 0 ? (ceData[0].volume / maxCE_volume) * 100 : 0;
    }

    if (peData.length > 0) {
        pePercent = maxPE_OI !== 0 ? (peData[0].oi / maxPE_OI) * 100 : 0;
        peVolumePercent = maxPE_volume !== 0 ? (peData[0].volume / maxPE_volume) * 100 : 0;
    }
     
      let cePercentStyle = {};
      let pePercentStyle = {};
      let cePercentStyleVolume = {};
      let pePercentStyleVolume = {};
  
      if (cePercent === 100) {
        cePercentStyle = { backgroundColor: "#b22c01",color: "#fff",textAlign: "center" };
      } else if (cePercent >= 95 && cePercent < 100) {
          cePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center"  };
      } else if (cePercent >= 75 && cePercent < 95) {
          cePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
      } else {
          cePercentStyle = { color: 'inherit' }; // Default color
      }

    // Set styles based on PE percentage
    if (pePercent === 100) {
        pePercentStyle = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
    } else if (pePercent >= 95 && pePercent < 100) {
        pePercentStyle = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
    } else if (pePercent >= 75 && pePercent < 95) {
        pePercentStyle = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center"};
    } else {
        pePercentStyle = { color: 'inherit' }; // Default color
    }

    // Set styles based on CE volume percentage
    if (ceVolumePercent === 100) {
        cePercentStyleVolume = {backgroundColor: "#b22c01",color: "#fff",textAlign: "center"  };
    } else if (ceVolumePercent >= 95 && ceVolumePercent < 100) {
        cePercentStyleVolume = {backgroundColor: "#e3d358",color: "#000",textAlign: "center" };
    } else if (ceVolumePercent >= 75 && ceVolumePercent < 95) {
        cePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
    } else {
        cePercentStyleVolume = { color: 'inherit' }; // Default color
    }

    // Set styles based on PE volume percentage
    if (peVolumePercent === 100) {
        pePercentStyleVolume = {backgroundColor: "green",color: "#fff",textAlign: "center"  };
    } else if (peVolumePercent >= 95 && peVolumePercent < 100) {
        pePercentStyleVolume = { backgroundColor: "#F6BE00",color: "#000",textAlign: "center"};
    } else if (peVolumePercent >= 75 && peVolumePercent < 95) {
        pePercentStyleVolume = {backgroundColor: "#f7f2b8",color: "#000",textAlign: "center" };
    } else {
        pePercentStyleVolume = { color: 'inherit' }; // Default color
    }
      rows.push(
          <tr key={i} >
              <td className="text-right-td">{ceData.length > 0 ? ceData[0].iv || 0 : 0}<br/><span>{ceData.length > 0 ? ceData[0].delta || 0 : 0}/{ceData.length > 0 ? ceData[0].gamma || 0 : 0}</span></td>
              <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button">19.73%<br/><span>25k</span></td>
              <td className="text-right-td" style={cePercentStyle} data-bs-toggle="modal" href="#exampleModalToggle" role="button">{cePercent !== undefined ? cePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(ceData[0] && ceData[0].oi !== undefined ? ceData[0].oi : 0) || 0}</span></td>
              <td className="text-right-td " style={cePercentStyleVolume} data-bs-toggle="modal" href="#exampleModalToggleVolumeCE" role="button" onClick={() => setStrickPrice(lower - i * 50)}>{ceVolumePercent !== undefined ? ceVolumePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(ceData[0] && ceData[0].volume !== undefined ? ceData[0].volume : 0) || 0}</span></td>
              <td className="text-right-td">{ceData[0] && ceData[0].vwap !== undefined ? ceData[0].vwap : 0}</td>
              <td className="text-right-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChg" role="button">{ceData[0] && ceData[0].last_price !== undefined ? ceData[0].last_price : 0}<br/><span><span>-4.50</span>(8)</span></td>
              <td className="text-center-td">{lower - i * 50}<br/><span>0.11</span></td>
              <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleLTPChg" role="button">{peData[0] && peData[0].last_price !== undefined ? peData[0].last_price : 0}<br/><span><span>-4.50</span>(8)</span></td>
              <td className="text-left-td" >{peData[0] && peData[0].vwap !== undefined ? peData[0].vwap : 0}</td>
              <td className="text-left-td" style={pePercentStyleVolume} data-bs-toggle="modal" href="#exampleModalToggleVolumePE" role="button" onClick={() => setStrickPrice(lower - i * 50)}>{peVolumePercent !== undefined ? peVolumePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(peData[0] && peData[0].volume !== undefined ? peData[0].volume : 0) || 0}</span></td>
              <td className="text-left-td" style={pePercentStyle} data-bs-toggle="modal" href="#exampleModalToggle" role="button">{pePercent !== undefined ? pePercent.toFixed(2) + "%" : "N/A"}<br/><span>{formatNumber(peData[0] && peData[0].oi !== undefined ? peData[0].oi : 0) || 0}</span></td>
              <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleMulti" role="button">0%<br/><span >-8.05K</span></td>
              <td className="text-left-td" data-bs-toggle="modal" href="#exampleModalToggleIV" role="button">{peData.length > 0 ? peData[0].iv || 0 : 0}<br/><span>{peData.length > 0 ? peData[0].delta || 0 : 0}/{peData.length > 0 ? peData[0].gamma || 0 : 0}</span></td>

          </tr>
      );
  }
  return rows;
};

  return (
    <>

    <div className="top">
      <table>
      <thead>
          <tr>
              <td colspan="2" className="red" scope="col">TvItm:6</td>
              <td colspan="1" className="red">LTPChg:530</td>
              <td colspan="1" className="red">OIChg:35%</td>
              <td colspan="2" className="red">(CE)R:22300</td>
              <td colspan="1" className="white">{nifty50.data?.["NSE:NIFTY FIN SERVICE"]?.timestamp }</td>
              <td colspan="2" className="green">(PE)S:22300</td>
              <td colspan="1" className="green">OIChg:65%</td>
              <td colspan="1" className="green">LTPChg:-611</td>
              <td colspan="2" className="green">TvItm:-3</td>
          </tr>    
      </thead>

      <thead>
        <tr>
        <td className="red" scope="col">IV</td>
        <td className="red">OI Chg</td>
        <td className="red">OI</td>
        <td className="red">Volume</td>
        <td className="red">VWAP</td>
        <td className="red">LTP/Chg(TV)</td>
        <td className="white">StrikePrice/PCROI</td>
        <td className="green">LTP/Chg(TV)</td>
        <td className="green">VWAP</td>
        <td className="green">Volume</td>
        <td className="green">OI</td>
        <td className="green">OI Chg</td>
        <td className="green">IV</td>
        </tr>    
      </thead>
      <tbody>
          {renderRows()}
          <tr className="center">
            <td colspan="4" ></td>
            <td colspan="4">
              <div className="text">
                <h4 data-bs-toggle="modal" href="#exampleModalToggleNIFTY" role="button">Spot:{nifty50.data?.["NSE:NIFTY FIN SERVICE"]?.last_price}</h4>
              </div>
            </td>
            <td colspan="4"></td>
          </tr>
         {renderLower()}
    </tbody>     
      </table>
      <div class="container mt-1" id="main">
        <div class="container text-center" id="conbg">
          <div class="row justify-content-center">
            <div class="col-md-6"> 
              <div class="table-responsive">
                <table class="table borderless">
                  <tbody>
                    <tr id="myhead">
                      <td>
                        <button type="button" className="btn btn-outline-info" data-bs-toggle="modal" href="#exampleModalToggleTV" role="button" tabindex="0" data-toggle="tooltip"  title="Time Value CE">TV CE:65%</button>
                      </td>
                      <td className="text-center" >
                        <p data-bs-toggle="modal" href="#exampleModalToggleDelta" role="button"> Delta CE:3.57 | Delta PE:3.45</p>
                        <p style={{marginTop:"-15px"}}> <span tabindex="0" data-toggle="tooltip"  title="LTP CE : 57161">LTP CE:65%</span> | <span tabindex="0" data-toggle="tooltip"  title="LTP PE : 57161">LTP PE:35%</span>  </p>
                      </td>
                      <td>
                        <button type="button" className="btn btn-outline-info" data-bs-toggle="modal" href="#exampleModalToggleTV" role="button" tabindex="0" data-toggle="tooltip"  title="Time Value PE"> LTP PE:35%</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="container mb-5" id="conbg">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="table-container">
                <div class="table-responsive"> 
                  <table class="table borderless">
                    <tbody id="mytbl">
                      <tr id="mytr">
                        <td scope="col" class="text-center" data-bs-toggle="modal" href="#singleMulti" role="button">CPR Volume:1.13</td>
                        <td scope="col" class="text-center" data-bs-toggle="modal" href="#singleMulti" role="button">PCR OI:1.30</td>
                        <td scope="col" class="text-center" data-bs-toggle="modal" href="#singleMulti" role="button">PCR OI Change : 0.79</td>
                      </tr>
                      <tr>
                        <td class="text-center">Total Volume CE:63.4M</td>
                        <td class="text-center">Total OI CE:47.3M</td>
                        <td class="text-center">Total OI Change CE: 6.97M</td>
                      </tr>
                      <tr>
                        <td class="text-center">Total Volume PE:56.3M</td>
                        <td class="text-center">Total OI PE:61.4M</td>
                        <td class="text-center">Total OI Change PE:5.51M</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    {/* single graph for OI */}
    <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-header">
            <h6>Percentage Graph</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <Line
          data={{
            labels: revenueData.map((data) => data.label),
            datasets: [
              {
                label: "Volume %",
                data: revenueData.map((data) => data['Volume']), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
              },
              {
                label: "OI %",
                data: revenueData.map((data) => data.OI),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
              },
              {
                label: "OI Change %",
                data: revenueData.map((data) => data['OI Change']), // Corrected
                backgroundColor: "#006e1c",
                borderColor: "#006e1c",
              },
              
            ],
          }}
          options={{
            scales: {
              x: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 5,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                position: 'right', // Display y-axis on the right side
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
                zoom: {
                pan: {
                  enabled: true, // Enable panning
                  mode: "x", // Allow panning in both x and y directions
                  speed: 20, // Panning speed
                  threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                  wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                  },
                  pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                  },
                  mode: "x", // Allow zooming in both x and y directions
                },
                },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />
          </div>
        </div>
      </div>
    </div>

    {/* multi graph for OI Change  */}
    <div className="modal fade" id="exampleModalToggleMulti" aria-hidden="true" aria-labelledby="exampleModalToggleLabelMulti" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '500px' }}> {/* Adjust the width and height as needed */}
        <div className="modal-content">
          <div className="modal-header">
            <h6>OI Charts</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {/* Navigation Bar */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="oi-change-tab" data-bs-toggle="tab" data-bs-target="#oi-change" type="button" role="tab" aria-controls="oi-change" aria-selected="false">OI Change</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link " id="oi-graph-tab" data-bs-toggle="tab" data-bs-target="#oi-graph" type="button" role="tab" aria-controls="oi-graph" aria-selected="true">OI Graph</button>
                </li>					
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="volume-tab" data-bs-toggle="tab" data-bs-target="#volume" type="button" role="tab" aria-controls="volume" aria-selected="false">Volume</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pcr-tab" data-bs-toggle="tab" data-bs-target="#pcr" type="button" role="tab" aria-controls="pcr" aria-selected="false">PCR</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="ce-build-up-tab" data-bs-toggle="tab" data-bs-target="#ce-build-up" type="button" role="tab" aria-controls="ce-build-up" aria-selected="false">CE Build Up</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pe-build-up-tab" data-bs-toggle="tab" data-bs-target="#pe-build-up" type="button" role="tab" aria-controls="pe-build-up" aria-selected="false">PE Build Up</button>
                </li>
              </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="oi-change" role="tabpanel" aria-labelledby="oi-change-tab">
                <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "OI Change PE-CE",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "OI Change PE",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                      {
                        label: "OI Change CE",
                        data: revenueData.map((data) => data['Volume']), // Corrected
                        backgroundColor: "#9bdee6",
                        borderColor: "#9bdee6",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
              </div>
              <div className="tab-pane fade " id="oi-graph" role="tabpanel" aria-labelledby="oi-graph-tab">
              <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "OI PE",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "OI CE",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                      {
                        label: "(PE-CE)",
                        data: revenueData.map((data) => data['Volume']), // Corrected
                        backgroundColor: "#9bdee6",
                        borderColor: "#9bdee6",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
              </div> 
              <div className="tab-pane fade" id="volume" role="tabpanel" aria-labelledby="volume-tab">
              <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "VOL PE",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#b22c01",
                        borderColor: "#b22c01",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "VOl CE",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#006e1c",
                        borderColor: "#006e1c",
                        yAxisID: 'y2',
                      },
                      {
                        label: "VOL CE-PE",
                        data: revenueData.map((data) => data['Volume']), // Corrected
                        backgroundColor: "#0061a4",
                        borderColor: "#0061a4",
                        yAxisID: 'y2', 
                      },
                      {
                        label: "LTP CE",
                        data: revenueData.map((data) => data['LTP CE']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2',
                        hidden:true
                      },
                      {
                        label: "LTP PE",
                        data: revenueData.map((data) => data['LTP PE']), // Corrected
                        backgroundColor: "#9527a8",
                        borderColor: "#9527a8",
                        yAxisID: 'y2', 
                        hidden:true
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
              </div>
              <div className="tab-pane fade" id="pcr" role="tabpanel" aria-labelledby="pcr-tab">
                <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "PCR of OI",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "PCR of OI Change",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2', // Assign a different y-axis ID
                        hidden: true // Set to true to disable by default
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
              </div>
              <div className="tab-pane fade" id="ce-build-up" role="tabpanel" aria-labelledby="ce-build-up-tab">
              <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "OI Change ",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "OI",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2', 
                        hidden:true
                      },
                      {
                        label: "LTP",
                        data: revenueData.map((data) => data['Volume']), // Corrected
                        backgroundColor: "#9bdee6",
                        borderColor: "#9bdee6",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
              </div>
              <div className="tab-pane fade" id="pe-build-up" role="tabpanel" aria-labelledby="pe-build-up-tab">
              <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "OI Change ",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "OI",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2', 
                        hidden:true
                      },
                      {
                        label: "LTP",
                        data: revenueData.map((data) => data['Volume']), // Corrected
                        backgroundColor: "#9bdee6",
                        borderColor: "#9bdee6",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Model for volume  CE*/}
    <div className="modal fade" id="exampleModalToggleVolumeCE" aria-hidden="true" aria-labelledby="exampleModalToggleLabelVolumeCE" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '290px', height: '100px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-body">
            <div className="negative-value" style={{ fontSize: "24px" }}>Possible Reversal for:<br /><span className="negative-value" style={{ fontSize: "24px" }}> {strickPrice !== null ? strickPrice : ''}</span></div>
            <div className="negative-value">Resistance: &nbsp;<span style={{ color: "black" }}>{spotPrice} <i className='bx bx-copy-alt'></i></span></div>
          </div>
        </div>
      </div>
    </div>

    {/* Model for volume  PE*/}
    <div className="modal fade" id="exampleModalToggleVolumePE" aria-hidden="true" aria-labelledby="exampleModalToggleLabelVolumePE" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '290px', height: '100px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-body">
            <div className="positive-value" style={{ fontSize: "24px" }}>Possible Reversal for:<br /><span className="positive-value" style={{ fontSize: "24px" }}> {strickPrice !== null ? strickPrice : ''}</span></div>
            <div className="positive-value">Support: &nbsp;<span style={{ color: "black" }}>{spotPrice} <i className='bx bx-copy-alt'></i></span></div>
          </div>
        </div>
      </div>
    </div>

    {/* Model for LTP Calculater*/}
    <div className="modal fade" id="exampleModalToggleLTPChg" aria-hidden="true" aria-labelledby="exampleModalToggleLabelLTPChg" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '390px', height: '100px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-body">
            <div className="normal-value" style={{ fontSize: "24px",fontStyle:"italic" }}>LTP Calculater 22500 CE</div>
            <input type='text' id="box" placeholder='Target Spot Price'  ></input>
            <div className="normal-value" style={{marginTop:"50px"}}>Buy Price: &nbsp;<span style={{ color: "black" }}>22785.83</span></div>
            <div className="negative-value">SL: &nbsp;<span style={{ color: "black" }}>22785.83</span></div>
            <div className="positive-value">Target: &nbsp;<span style={{ color: "black" }}>22785.83 </span></div>

            <div className="negative-value" style={{fontSize:"12px",marginTop:"50px"}}>This is Rough Estimate, Calculation is based on delta.<br/>Please use your own judgement and do not trade based on this alone. </div>
          </div>
        </div>
      </div>
    </div>

    {/* single graph for Future buildup:NIFTY */}
    <div className="modal fade" id="exampleModalToggleNIFTY" aria-hidden="true" aria-labelledby="exampleModalToggleLabelNIFTY" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h6 style={{textAlign:"center"}}>Future Buildup:NIFTY</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <Line
                  data={{
                    labels: revenueData.map((data) => data.label),
                    datasets: [
                      {
                        label: "Future Price",
                        data: revenueData.map((data) => data.OI),
                        backgroundColor: "#064FF0",
                        borderColor: "#064FF0",
                        yAxisID: 'y1', // Assign y-axis ID
                      },
                      {
                        label: "Future OI Change",
                        data: revenueData.map((data) => data['OI Change']),
                        backgroundColor: "#FF3030",
                        borderColor: "#FF3030",
                        yAxisID: 'y2', // Assign a different y-axis ID
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y1: { // Configuration for the first y-axis
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 10,
                        },
                      },
                      y2: { // Configuration for the second y-axis
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 200,
                        ticks: {
                          stepSize: 20,
                        },
                      },
                      x: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                          stepSize: 5,
                        },
                        grid: {
                          display: false,
                        },
                      },
                    },
                    responsive: true,
                    plugins: {
                      title: {
                        text: "Percentage Graph",
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                      },
                      zoom: {
                        pan: {
                          enabled: true,
                          mode: "x",
                          speed: 20,
                          threshold: 10,
                        },
                        zoom: {
                          wheel: {
                            enabled: true,
                          },
                          pinch: {
                            enabled: true,
                          },
                          mode: "x",
                        },
                      },
                    },
                    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                    interaction: {
                      mode: 'nearest',
                      axis: 'xy',
                    },
                    transitions: {
                      show: {
                        animations: {
                          x: {
                            from: 0
                          },
                          y: {
                            from: 0
                          }
                        }
                      },
                      hide: {
                        animations: {
                          x: {
                            to: 0
                          },
                          y: {
                            to: 0
                          }
                        }
                      }
                    },
                  }}    
                />
          </div>
        </div>
      </div>
    </div>

    {/* single graph for combine price chart */}
    <div className="modal fade" id="exampleModalToggleIV" aria-hidden="true" aria-labelledby="exampleModalToggleLabelIV" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-header">
            <h6>Combine Price Chart</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <Line
          data={{
            labels: revenueData.map((data) => data.label),
            datasets: [
              {
                label: "Combine Price ",
                data: revenueData.map((data) => data['Volume']), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
              },
              {
                label: "CE Price",
                data: revenueData.map((data) => data.OI),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
              },
              {
                label: "PE Price",
                data: revenueData.map((data) => data['OI Change']), // Corrected
                backgroundColor: "#006e1c",
                borderColor: "#006e1c",
              },
              
            ],
          }}
          options={{
            scales: {
              x: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 5,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                position: 'right', // Display y-axis on the right side
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
                zoom: {
                pan: {
                  enabled: true, // Enable panning
                  mode: "x", // Allow panning in both x and y directions
                  speed: 20, // Panning speed
                  threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                  wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                  },
                  pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                  },
                  mode: "x", // Allow zooming in both x and y directions
                },
                },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />
          </div>
        </div>
      </div>
    </div>

    {/* single graph for Time value */}
    <div className="modal fade" id="exampleModalToggleTV" aria-hidden="true" aria-labelledby="exampleModalToggleLabelTV" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> {/* Adjust the width as needed */}
        <div className="modal-content">
          <div className="modal-header">
            <h6>Time Value</h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <Line
          data={{
            labels: revenueData.map((data) => data.label),
            datasets: [
              {
                label: "TV (CE-PE)",
                data: revenueData.map((data) => data['Volume']), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
                hidden:true
              },
              {
                label: "TV PE",
                data: revenueData.map((data) => data.OI),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
              },
              {
                label: "TV CE",
                data: revenueData.map((data) => data['OI Change']), // Corrected
                backgroundColor: "#006e1c",
                borderColor: "#006e1c",
              },
              
            ],
          }}
          options={{
            scales: {
              x: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 5,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                position: 'right', // Display y-axis on the right side
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
                zoom: {
                pan: {
                  enabled: true, // Enable panning
                  mode: "x", // Allow panning in both x and y directions
                  speed: 20, // Panning speed
                  threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                  wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                  },
                  pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                  },
                  mode: "x", // Allow zooming in both x and y directions
                },
                },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />
          </div>
        </div>
      </div>
    </div>

    {/* single graph for Delta Near sport price */}
    <div className="modal fade" id="exampleModalToggleDelta" aria-hidden="true" aria-labelledby="exampleModalToggleLabelDelta" tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '300px' }}> {/* Adjust the width as needed */}
      <div className="modal-content">
        <div className="modal-header">
          <h6>Delta Near Spot Price</h6>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
        <Line
          data={{
            labels: revenueData.map((data) => data.label),
            datasets: [
              {
                label: "Delta CE",
                data: revenueData.map((data) => data['Volume']), // Corrected
                backgroundColor: "#0061a4",
                borderColor: "#0061a4",
              },
              {
                label: "Delta PE",
                data: revenueData.map((data) => data.OI),
                backgroundColor: "#9a25ae",
                borderColor: "#9a25ae",
              },
              {
                label: "Delta (CE-PE)",
                data: revenueData.map((data) => data['OI Change']), // Corrected
                backgroundColor: "#006e1c",
                borderColor: "#006e1c",
              },
              
            ],
          }}
          options={{
            scales: {
              x: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 5,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                position: 'right', // Display y-axis on the right side
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
            responsive: true,
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
              zoom: {
                pan: {
                enabled: true, // Enable panning
                mode: "x", // Allow panning in both x and y directions
                speed: 20, // Panning speed
                threshold: 10, // Minimal pan distance required before actually applying pan
                },
                zoom: {
                wheel: {
                  enabled: true, // Enable zooming with mouse wheel
                },
                pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                },
                mode: "x", // Allow zooming in both x and y directions
                },
              },
            },
            events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
            interaction: {
              mode: 'nearest',
              axis: 'xy',
            },
            transitions: {
              show: {
                animations: {
                  x: {
                    from: 0
                  },
                  y: {
                    from: 0
                  }
                }
              },
              hide: {
                animations: {
                  x: {
                    to: 0
                  },
                  y: {
                    to: 0
                  }
                }
              }
            },
          }}	
        />
        </div>
      </div>
    </div>
    </div>

    {/* multi graph for OI Change  */}
    <div className="modal fade" id="singleMulti" aria-hidden="true" aria-labelledby="singleMulti" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '800px', height: '500px' }}>
            <div className="modal-content">
                <div className="modal-header">
                    <h6>PCR and PCR Charts</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    {/* Navigation Bar */}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'oi-change' ? 'active' : ''}`} id="oi-change-tab" data-bs-toggle="tab" data-bs-target="#oi-change" type="button" role="tab" aria-controls="oi-change" aria-selected={activeTab === 'oi-change'} onClick={() => handleTabChange('oi-change')}>OI Change</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'oi' ? 'active' : ''}`} id="oi-tab" data-bs-toggle="tab" data-bs-target="#oi" type="button" role="tab" aria-controls="oi" aria-selected={activeTab === 'oi'} onClick={() => handleTabChange('oi')}>OI Graph</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeTab === 'volume' ? 'active' : ''}`} id="volume-graph-tab" data-bs-toggle="tab" data-bs-target="#volume-graph" type="button" role="tab" aria-controls="volume-graph" aria-selected={activeTab === 'volume'} onClick={() => handleTabChange('volume')}>Volume</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane fade ${activeTab === 'oi-change' ? 'show active' : ''}`} id="oi-change" role="tabpanel" aria-labelledby="oi-change-tab">
          <Line
                data={{
                  labels: revenueData.map((data) => data.label),
                  datasets: [
                  {
                    label: "OI Change PCR(PE/CE)",
                    data: revenueData.map((data) => data['Volume']),
                    backgroundColor: "#0061a4",
                    borderColor: "#0061a4",
                  },
                  {
                    label: "OI Change (PE-CE)",
                    data: revenueData.map((data) => data.OI),
                    backgroundColor: "#9a25ae",
                    borderColor: "#9a25ae",
                    hidden:true
                  },
                  {
                    label: "OI Change PE",
                    data: revenueData.map((data) => data['OI Change']),
                    backgroundColor: "#34deeb",
                    borderColor: "#34deeb",
                    hidden:true
                  },
                  {
                    label: "OI Change CE",
                    data: revenueData.map((data) => data['LTP CE']),
                    backgroundColor: "#006e1c",
                    borderColor: "#006e1c",
                    hidden:true
                  }
                  ],
                }}
                options={{
                  scales: {
                    x: {
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 5,
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      position: 'right', // Display y-axis on the right side
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 10,
                      },
                    },
                  },
                  responsive: true,
                  plugins: {
                    tooltip: {
                      mode: 'index',
                      intersect: false,
                    },
                    zoom: {
                      pan: {
                      enabled: true, // Enable panning
                      mode: "x", // Allow panning in both x and y directions
                      speed: 20, // Panning speed
                      threshold: 10, // Minimal pan distance required before actually applying pan
                      },
                      zoom: {
                      wheel: {
                        enabled: true, // Enable zooming with mouse wheel
                      },
                      pinch: {
                        enabled: true, // Enable zooming with pinch gesture
                      },
                      mode: "x", // Allow zooming in both x and y directions
                      },
                    },
                  },
                  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                  interaction: {
                    mode: 'nearest',
                    axis: 'xy',
                  },
                  transitions: {
                    show: {
                      animations: {
                        x: {
                          from: 0
                        },
                        y: {
                          from: 0
                        }
                      }
                    },
                    hide: {
                      animations: {
                        x: {
                          to: 0
                        },
                        y: {
                          to: 0
                        }
                      }
                    }
                  },
                }}	
              />
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'oi' ? 'show active' : ''}`} id="oi" role="tabpanel" aria-labelledby="oi-tab">
          <Line
                data={{
                  labels: revenueData.map((data) => data.label),
                  datasets: [
                  {
                    label: "OI PCR (PE/CE)",
                    data: revenueData.map((data) => data['Volume']),
                    backgroundColor: "#0061a4",
                    borderColor: "#0061a4",
                  },
                  {
                    label: "OI (PE-CE)",
                    data: revenueData.map((data) => data.OI),
                    backgroundColor: "#9a25ae",
                    borderColor: "#9a25ae",
                    hidden:true
                  },
                  {
                    label: "OI PE",
                    data: revenueData.map((data) => data['OI Change']),
                    backgroundColor: "#34deeb",
                    borderColor: "#34deeb",
                    hidden:true
                  },
                  {
                    label: "OI CE",
                    data: revenueData.map((data) => data['LTP CE']),
                    backgroundColor: "#006e1c",
                    borderColor: "#006e1c",
                    hidden:true
                  }
                  ],
                }}
                options={{
                  scales: {
                    x: {
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 5,
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      position: 'right', // Display y-axis on the right side
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 10,
                      },
                    },
                  },
                  responsive: true,
                  plugins: {
                    tooltip: {
                      mode: 'index',
                      intersect: false,
                    },
                    zoom: {
                      pan: {
                      enabled: true, // Enable panning
                      mode: "x", // Allow panning in both x and y directions
                      speed: 20, // Panning speed
                      threshold: 10, // Minimal pan distance required before actually applying pan
                      },
                      zoom: {
                      wheel: {
                        enabled: true, // Enable zooming with mouse wheel
                      },
                      pinch: {
                        enabled: true, // Enable zooming with pinch gesture
                      },
                      mode: "x", // Allow zooming in both x and y directions
                      },
                    },
                  },
                  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                  interaction: {
                    mode: 'nearest',
                    axis: 'xy',
                  },
                  transitions: {
                    show: {
                      animations: {
                        x: {
                          from: 0
                        },
                        y: {
                          from: 0
                        }
                      }
                    },
                    hide: {
                      animations: {
                        x: {
                          to: 0
                        },
                        y: {
                          to: 0
                        }
                      }
                    }
                  },
                }}	
              />
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'volume' ? 'show active' : ''}`} id="volume-graph" role="tabpanel" aria-labelledby="volume-graph-tab">
          <Line
                data={{
                  labels: revenueData.map((data) => data.label),
                  datasets: [
                  {
                    label: "Volume CPR (CE/PE)",
                    data: revenueData.map((data) => data['Volume']),
                    backgroundColor: "#0061a4",
                    borderColor: "#0061a4",
                  },
                  {
                    label: "Volume (CE-PE)",
                    data: revenueData.map((data) => data.OI),
                    backgroundColor: "#9a25ae",
                    borderColor: "#9a25ae",
                    hidden:true
                  },
                  {
                    label: "Volume PE",
                    data: revenueData.map((data) => data['OI Change']),
                    backgroundColor: "#34deeb",
                    borderColor: "#34deeb",
                    hidden:true
                  },
                  {
                    label: "Volume CE",
                    data: revenueData.map((data) => data['LTP CE']),
                    backgroundColor: "#006e1c",
                    borderColor: "#006e1c",
                    hidden:true
                  }
                  ],
                }}
                options={{
                  scales: {
                    x: {
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 5,
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      position: 'right', // Display y-axis on the right side
                      suggestedMin: 0,
                      suggestedMax: 100,
                      ticks: {
                        stepSize: 10,
                      },
                    },
                  },
                  responsive: true,
                  plugins: {
                    tooltip: {
                      mode: 'index',
                      intersect: false,
                    },
                    zoom: {
                      pan: {
                      enabled: true, // Enable panning
                      mode: "x", // Allow panning in both x and y directions
                      speed: 20, // Panning speed
                      threshold: 10, // Minimal pan distance required before actually applying pan
                      },
                      zoom: {
                      wheel: {
                        enabled: true, // Enable zooming with mouse wheel
                      },
                      pinch: {
                        enabled: true, // Enable zooming with pinch gesture
                      },
                      mode: "x", // Allow zooming in both x and y directions
                      },
                    },
                  },
                  events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
                  interaction: {
                    mode: 'nearest',
                    axis: 'xy',
                  },
                  transitions: {
                    show: {
                      animations: {
                        x: {
                          from: 0
                        },
                        y: {
                          from: 0
                        }
                      }
                    },
                    hide: {
                      animations: {
                        x: {
                          to: 0
                        },
                        y: {
                          to: 0
                        }
                      }
                    }
                  },
                }}	
              />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
    
  )
}
