import React from "react";
import Sidebar from "./Component/Sidebar";
import Header from "./Component/Header";
import Dashboard from "./Component/Dashboard";
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from "./Component/Login";
import Register from "./Component/Register";
import ForgotPassword from "./Component/ForgotPassword";
import ResetPassword from "./Component/ResetPassword";
import BackTester from "./Component/BackTester";
import KiteLogin from "./Component/KiteLogin";
import {UserManagement}  from "./Component/UserManagement";
import EditUser from "./Component/EditUser";

function App() {
  const location = useLocation();
  
  // Retrieve user data from sessionStorage
  let userData = JSON.parse(sessionStorage.getItem("userData"));

  return (
    <>
    {userData && location.pathname !== '/login' && location.pathname !== '/register' &&  (
      <>
        <Sidebar />
        <Header />
      </>
    )}
    
    <Routes>
        <Route path="/" element={<Dashboard/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/forgotpassword" element={<ForgotPassword/>} />
        <Route path="/resetpassword" element={<ResetPassword/>} />
        <Route path="/backtester" element={<BackTester/>} />
        <Route path="/kitelogin" element={<KiteLogin/>} />
        <Route path="/user" element={<UserManagement/>} />
        <Route path="/edituser/:id" element={<EditUser/>} />
      </Routes>
    </>
  );
}

export default App;
