import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
const EditUser = () => {
  const { id } = useParams();
  const [values, setValues] = useState({
    user_role: "",
    name: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
    contact: "",
  });
  const navigate = useNavigate();
  const getUserData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/getadmin/${id}`
      );
      if (res.status === 200 && res.data.data.length > 0) {
        const userData = res.data.data[0];
        setValues({
          user_role: userData.user_role,
          name: userData.name,
          email: userData.email,
          newPassword: "",
          confirmPassword: "",
          contact: userData.contact,
        });
      } else {
        Swal.fire({
          type: "error",
          icon: "error",
          title: "User not found",
        });
        navigate("/user");
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        type: "error",
        icon: "error",
        title: "Something went wrong",
      });
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const contactRegex = /^\d{10}$/;

        const trimmedStudent = {
            name: values.name.trim(),
            email: values.email.trim(),
            newPassword: values.newPassword.trim(),
        };

        if (
            !trimmedStudent.name ||
            !trimmedStudent.email ||
            !trimmedStudent.newPassword ||
            !values.name ||
            !values.user_role ||
            !values.email ||
            !values.contact
        ) {
            Swal.fire({
                title: "Please enter User Data",
                icon: "warning",
            });
        } else if (!contactRegex.test(values.contact)) {
            Swal.fire({
                title: "Invalid Contact Format",
                text: "Contact number must be exactly 10 digits",
                icon: "warning",
            });
        } else if (values.newPassword !== values.confirmPassword) {
            // Check if new password and confirm password match
            Swal.fire({
                title: "Passwords does not match",
                icon: "warning",
            });
        } else {
            const res = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/update/${id}`,
                {
                    ...values,
                    password: values.newPassword, // Use the new password value
                }
            );

            if (res.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "User insertion successful",
                    timer: 1500,
                }).then(() => {
                    navigate("/user");
                });
            } else {
                Swal.fire({
                    title: "User insertion failed",
                    icon: "error",
                });
            }
        }
    } catch (err) {
        console.error(err);

        Swal.fire({
            icon: "error",
            title: err.message || "Something went wrong",
            showConfirmButton: true,
        });
    }
};

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Forms</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="/user">
                      <i className="bx bx-home-alt"></i>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit User
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 mx-auto">
              <div className="card">
                <div className="card-body p-4">
                  <h5 className="mb-4">Edit User</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <label
                        htmlFor="input49"
                        className="col-sm-3 col-form-label"
                      >
                        User Name
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="bx bx-user"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="input49"
                            name="name"
                            value={values.name}
                            placeholder="Your Name"
                            onChange={(e) =>
                              setValues({ ...values, name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="input50"
                        className="col-sm-3 col-form-label"
                      >
                        Phone No
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="bx bx-microphone"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            id="input50"
                            name="contact"
                            value={values.contact}
                            placeholder="Phone No"
                            onChange={(e) =>
                              setValues({ ...values, contact: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="input51"
                        className="col-sm-3 col-form-label"
                      >
                        Email Address
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="bx bx-envelope"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={values.email}
                            id="input51"
                            placeholder="Email"
                            onChange={(e) =>
                              setValues({ ...values, email: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="input52"
                        className="col-sm-3 col-form-label"
                      >
                        New Password
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="bx bx-lock-open"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            id="input52"
                            name="newPassword"
                            value={values.newPassword}
                            placeholder="Enter New Password"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                newPassword: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="input53"
                        className="col-sm-3 col-form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-sm-9">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="bx bx-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            id="input53"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            placeholder="Confirm Password"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                confirmPassword: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="input39"
                        className="col-sm-3 col-form-label"
                      >
                        User Role
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-select"
                          id="input39"
                          name="user_role"
                          value={values.user_role}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValues((prevState) => ({
                              ...prevState,
                              user_role: value,
                            }));
                          }}
                        >
                          <option disabled>Select User Role</option>
                          <option value="admin">Admin</option>
                          <option value="user">User</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3"></div>
                      <div className="col-sm-9">
                        <div className="d-md-flex d-grid align-items-center gap-3">
                          <button
                            type="submit"
                            className="btn btn-primary px-4"
                          >
                            Submit
                          </button>
                          <NavLink to="/user" className="btn btn-primary px-4">
                            Back
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
