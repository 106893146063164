import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";

const Sidebar = () => {
  // Retrieve user data from sessionStorage
  const userData = JSON.parse(sessionStorage.getItem('userData'));
  // Check if user data exists and get the user role
  const userRole = userData ? userData.role : null;  
	const navigate = useNavigate();
	console.log(userData.result[0].id);
	
	  const handleLogout = (userId) => {
		Swal.fire({
		  title: "Logout",
		  text: "Are you sure you want to logout?",
		  icon: "warning",
		  showCancelButton: true,
		  confirmButtonColor: "#3085d6",
		  cancelButtonColor: "#d33",
		  confirmButtonText: "Yes, logout!",
		}).then((result) => {
		  if (result.isConfirmed) {
			axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout/${userId}`)
			  .then(res => {
				if (res.data.success === 0) {
				  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				  sessionStorage.removeItem('userData');
				  navigate("/login");
		
				  Swal.fire({
					icon: "success",
					title: "Logout",
					text: "Logout successful",
					timer: 1500,
				  });
				} else {
				  // If logout fails, show an error message
				  Swal.fire({
					icon: "error",
					title: "Logout",
					text: "Logout failed. Please try again.",
				  });
				}
			  })
			  .catch(err => console.log(err));
		  }
		});
	  }
  return (
    <div className="wrapper">
      <div className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div>
            <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon" />
          </div>
          <div>
            <h4 className="logo-text">TSL Fintech</h4>
          </div>
          <div className="toggle-icon ms-auto">
            <i className='bx bx-arrow-back'></i>
          </div>
        </div>
        {userRole === "user" && (
          <ul className="metismenu" id="menu">
            <li>
              <NavLink to="/">
                <div className="parent-icon"><i className='bx bx-home-alt'></i></div>
                <div className="menu-title">Aatmanirbhar Option Chain</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/backtester">
              <i class="bi bi-clock"  style={{ fontSize: '1.3rem' }}></i>
                <div className="menu-title">Back Tester</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/register">
                <div className="parent-icon"><i className="bx bx-lock"></i></div>
                <div className="menu-title">Register</div>
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item d-flex align-items-center" to="#" onClick={() => handleLogout(userData.result[0].id)}>
                <i className="bx bx-log-out-circle" style={{ fontSize: '1.4rem' }}></i><div className="menu-title">Logout</div>
                </NavLink>
						</li>
          </ul>
        )}
        {userRole === "admin" && (
          <ul className="metismenu" id="menu">
            <li>
              <NavLink to="/">
                <div className="parent-icon"><i className='bx bx-home-alt'></i></div>
                <div className="menu-title">Aatmanirbhar Option Chain</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/backtester">
              <i class="bi bi-clock"  style={{ fontSize: '1.3rem' }}></i>
                <div className="menu-title">Back Tester</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/kitelogin">
                <div className="parent-icon"><i className="bx bx-category"></i></div>
                <div className="menu-title">Kite Login</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/user">
              <i class="bi bi-person"  style={{ fontSize: '1.4rem' }}></i>
                <div className="menu-title">User</div>
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item d-flex align-items-center" to="#" onClick={() => handleLogout(userData.result[0].id)}>
                <i className="bx bx-log-out-circle" style={{ fontSize: '1.4rem' }}></i><div className="menu-title">Logout</div>
                </NavLink>
						</li>
          </ul>
        )}
      </div>
      <div className="overlay toggle-icon"></div>
      <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
      <footer className="page-footer">
        <p className="mb-0">Copyright © 2024. All right reserved.</p>
      </footer>
    </div>
  );
}

export default Sidebar;
