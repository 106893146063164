import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../style.css";
import "../table.css";
import Chart from "chart.js/auto"; // Import Chart directly from 'chart.js/auto'
import zoomPlugin from "chartjs-plugin-zoom";
import axios from "axios";
import { Nifty50 } from "./Nifty50";
import { BankNifty } from "./BankNifty";
import { NiftyFinSrvice } from "./NiftyFinSrvice";
import Swal from 'sweetalert2';
import { Sensex } from "./Sensex";
import { MidCapNifty } from "./MidCapNifty";
Chart.register(zoomPlugin);

const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState("Nifty");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        if (!userData) {
          // If userData is not available, redirect to login
          navigate("/login");
          return;
        }

        const userId = userData.result[0].id;

        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/getadmin/${userId}`
        );

        if (res.status === 200 && res.data.data.length > 0) {
          const userDataFromServer = res.data.data[0];
          if (userDataFromServer.status === 0) {
            // If user status is inactive, clear sessionStorage and redirect to login
            sessionStorage.clear();
            Swal.fire({
              icon: "error",
              title: "Your account is deactivated",
            });
            navigate("/login");
          }
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    };

    // Initial check when component mounts
    checkUserStatus();

    // Set up interval to check user status every 2 minutes
    const intervalId = setInterval(checkUserStatus, 2 * 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="wrapper">
        {/* <!--start page wrapper --> */}
        <div className="page-wrapper">
          <div className="page-content">
            <div className="container-fluid" id="headbtn">
              <h6 className="mt-2 text-uppercase">DataTable Import</h6>
              <select
                className="form-select"
                style={{ width: "auto" }}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="Nifty">Nifty</option>
                <option value="BankNifty">Bank Nifty</option>
                <option value="NIFTYFINSERVICE">NIFTY FIN SERVICE</option>
                <option value="SENSEX">SENSEX</option>
                <option value="NIFTYMIDCAPSELECT">NIFTY MIDCAP SELECT</option>
              </select>
            </div>
            <hr />
            {selectedOption === "Nifty" && <Nifty50 />}
            {selectedOption === "BankNifty" && <BankNifty/>}
            {selectedOption === "NIFTYFINSERVICE" && <NiftyFinSrvice/>}
            {selectedOption === "SENSEX" && <Sensex/>}
            {selectedOption === "NIFTYMIDCAPSELECT" && <MidCapNifty/>}
          </div>
        </div>

        <div className="overlay toggle-icon"></div>
        <a href="javaScript:;" className="back-to-top">
          <i className="bx bxs-up-arrow-alt"></i>
        </a>
      </div>
    </>
  );
};

export default Dashboard;
