import React, { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

const API_KEY = process.env.REACT_APP_API_KEY; // Your Kite API key
const SECRET = process.env.REACT_APP_SECRET; 
const REDIRECT_URL = "http://tslfintech.com:3000/"; // Your redirect URL after login

const KiteLogin = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [requestToken, setRequestToken] = useState(null);
 
  const handleLogin = async () => {
    try {
      // Redirect user to Kite login page
      window.location.href = `https://kite.trade/connect/login?api_key=${API_KEY}&v=3`;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const requestToken = urlParams.get('request_token');
//     setRequestToken(requestToken);
//     console.log("Request Token from URL:", requestToken); // Log the request token
//   }, []);

//   const getAccessToken = async () => {
//     try {
//         console.log("Request Token in getrequestToken:", requestToken); // Log the request token
//         const response = await axios.post('http://tslfintech.com:8000/api/login', { request_token: requestToken });
//         if (response.data.success === 0) {
//             setAccessToken(response.data.data.access_token);
//             localStorage.setItem('accessToken', response.data.data.access_token);
//         } else {
//             console.error('Error fetching access token:', response.data.error);
//             if (response.status === 403) {
//                 localStorage.removeItem('accessToken');
//             }
//         }
//     } catch (error) {
//         console.error('Error fetching access token:', error);
//     }
// };


//   // Call getAccessToken when requestToken is set
//   useEffect(() => {
//     if (requestToken) {
//       getAccessToken();
//     }
//   }, [requestToken]);

  
  return (
  
  
     <div className="wrapper">
       <div className="page-wrapper">
         <div className="page-content">
           <h6 className="mb-0 text-uppercase">Kite Login</h6>
           <hr />
          <p>Request Token : {requestToken}</p>
           {accessToken ? (
        <p>Access Token: {accessToken}</p>
      ) : (
        <button onClick={handleLogin}>Login with Kite</button>
      )}
     
         </div>
       </div>
     </div>
  );
};

export default KiteLogin;
